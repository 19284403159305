import React from 'react'
import { Check, TriangleAlert, X } from 'lucide-react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { updateFirstTime } from '../../../../config/store/reducers/authReducer'

const Modal = ({
  title,
  desc,
  imageicon,
  cardmultiplebtn,
  type,
  link,
  isOpen,
  onClose,
  welcomeicon
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // Icon logic based on success or failure type
  const icon =
    type === 'success'
      ? <Check className='text-darkGreen w-[80px] h-[80px]' size={40} />
      : <TriangleAlert className='text-red-400 w-[80px] h-[80px]' size={40} />

  // Border and background image class based on the modal type
  const noborder =
    type === 'success'
      ? 'border-solid border-darkgreen border-2'
      : 'border-none'
  const backgroundimage = welcomeicon
    ? 'bg-modalimg'
    : type === 'success'
      ? 'bg-modalimg'
      : 'bg-errorbg'

  // Button titles
  const btntitle = type === 'success' ? 'Continue' : 'Try Again'

  // Function to handle redirection when the button is clicked
  const handleRedirect = () => {
    if (type === 'success' && link) {
      navigate(link) // Navigate to the link if it's a success type
    } else {
      onClose() // Close the modal if it's not a success type
    }
    dispatch(updateFirstTime()) // Update first time regardless of type
  }

  if (!isOpen) return null

  return (
    <div className='fixed inset-0 bg-black top-[-20px] bg-opacity-50 flex items-center justify-center z-50'>
      <div className='bg-white lg:w-[30%] h-[400px] w-[90%]'>
        <div className='dialog'>
          {/* Modal Top Section */}
          <div className={`w-full h-[50%] ${backgroundimage} p-4 bg-cover bg-center bg-screen`}>
            {!welcomeicon && (
              <button className='b-none'>
                <X
                  size={20}
                  className='text-red-600 w-6 h-6 rounded-full bg-white'
                  onClick={onClose}
                />
              </button>
            )}
            <div className='w-[50%] mx-auto h-full center'>
              {welcomeicon
                ? (
                  <div className='w-[30%] mx-auto h-full center'>
                    <img src={welcomeicon} alt='welcome icon' />
                  </div>
                  )
                : imageicon
                  ? (
                    <div className='w-[30%] mx-auto h-full center'>
                      <img src={imageicon} alt='icon' />
                    </div>
                    )
                  : (
                    <div className={`${noborder} rounded-full p-2 w-[50%] center`}>
                      {icon}
                    </div>
                    )}
            </div>
          </div>

          {/* Modal Bottom Section */}
          <div className='w-full h-[50%] center flex-col p-5 text-center text-black space-y-3'>
            <h1 className='font-semibold'>{title}</h1>
            <p className='ts'>{desc}</p>
            {welcomeicon ? (
              <div className='w-full center gap-2 ts text-white mt-3'>
                <button
                  onClick={handleRedirect} // Redirect on click
                  className='w-[48%] h-[50px] bg-[#DDDDDD] text-black rounded-md ts'
                >
                  Skip for now
                </button>
                <Link
                  to='/operational-calendar/create-operational-calendar'
                  className='w-[48%] h-[50px] bg-darkGreen text-white rounded-md ts text-center flex justify-center items-center'

                >
                  Create calendar
                </Link>
              </div>
            ) : cardmultiplebtn
              ? (
                <div className='w-full center gap-2 ts text-white mt-3'>
                  <button className='w-[50%] h-[50px] bg-[#D92534] rounded-md ts'>
                    No, Cancel
                  </button>
                  <button className='w-[50%] h-[50px] bg-[#5CB85C] rounded-md ts'>
                    Yes, Change
                  </button>
                </div>
                )
              : (
                <button className='rounded-lg h-[50px] bg-darkGreen text-white w-[70%] mx-auto ts' onClick={handleRedirect}>
                  {btntitle}
                </button>
                )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
