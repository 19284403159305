import React, { useEffect, useState } from 'react'
import InvestContainer from '../InvestContainer/InvestContainer'
import useBackendService from '../../../../config/services/backend-service'

const RegularInvest = ({ unlock = false }) => {
  const [data, setData] = useState([])

  const { mutate: fetchInvest } = useBackendService(
    '/investments?status=approved',
    'get',
    {
      onSuccess: response => {
        setData(response.data)
      },
      onError: error => {
        console.error('Failed to fetch products:', error)
      }
    }
  )

  useEffect(() => {
    fetchInvest()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='grid grid-cols-2 lg:grid-cols-3 gap-2 lg:gap-10 p-5 '>
      {data.map(item => (
        <div className='lg:w-full w-[full]' key={item.id}>
          <InvestContainer item={item} unlock={unlock} />
        </div>
      ))}
    </div>
  )
}

export default RegularInvest
