import React from 'react'
import './TrendingNews.css'

const formatDate = dateString => {
  const date = new Date(dateString)
  const options = { day: '2-digit', month: 'long', year: 'numeric' }
  return date.toLocaleDateString('en-GB', options)
}

const TrendingNews = ({ data, unlock }) => {
  const trendingNews = data.filter(item => item.category === 'trending')

  return (
    <div className='w-full'>
      <h1 className='w-full font-semibold'>Trending News</h1>
      <div className=' trending-news-container rounded-lg w-full flex gap-2'>
        {trendingNews.map(item => (
          <div className=' bg-[#f9f9f9] trending-news-item rounded-lg p-3 space-y-3 w-1/2 lg:w-[30%]  flex flex-col' key={item.id}>
            <div className='w-full h-[180px]'>
              <img
                src={item.image_url}
                alt={item.title}
                className='object-cover rounded-lg w-full h-full'
              />
            </div>
            <div className='w-full flex gap-2 justify-start items-center ts'>
              <div className='circle' />
              {formatDate(item.created_at)}
            </div>
            <h1 className='font-semibold'>{item.title}</h1>
            <p className='text-gray-500 text-sm w-full line-clamp-2'>
              {item.body}

              <div className='font-semibold italic text-darkGreen underline'>
                {unlock
                  ? (
                    <a href={`/market/agrinews/${item.id}`}>Read More</a>
                    )
                  : (
                    <a href={`/agrinews/${item.id}`}>Read More</a>
                    )}
              </div>
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TrendingNews
