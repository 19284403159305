import React from 'react'
import { ChevronRight } from 'lucide-react'
import { Link } from 'react-router-dom'
import './Index.css'

function InvestContainer ({ item, unlock }) {
  return (
    <div className='basic-investment-content-ctn w-full' key={item.id}>
      <div
        className='w-full h-[130px] lg:h-[200px] p-5 flex justify-end rounded-lg overflow-hidden bg-cover bg-center'
        style={{ backgroundImage: `url(${item.image_url})` }}
      >
        <div className='investment'>
          <p>investment: {item.user_investment_count}</p>
        </div>
      </div>
      <div className='flex flex-row justify-between items-center'>
        <div className='flex flex-col-reverse lg:flex-col'>
          <p className='text-black font-[600] text-[14px] lg:text-[18px]'>
            {item.title}
          </p>
          <div className='font-[500] text-grey text-[12px] lg:text-[16px]'>
            <p>{item.about}</p>
          </div>
        </div>
        {unlock
          ? (
            <Link
              to={`/market/investment/${item.id}`}
              className='hidden lg:flex cursor-pointer items-center flex-row gap-[10px] text-primary'
            >
              <p className='text-[16px] font-[700]'>Check out</p>
              <ChevronRight size={22} />
            </Link>
            )
          : (
            <Link
              to={`/investment/${item.id}`}
              className='hidden lg:flex cursor-pointer items-center flex-row gap-[10px] text-primary'
            >
              <p className='text-[16px] font-[700]'>Check out</p>
              <ChevronRight size={22} />
            </Link>
            )}
      </div>
    </div>
  )
}

export default InvestContainer
