import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import TextInput from '../../../SharedComponents/TextInput/TextInput'
import { useAuth } from '../../../../../config/contexts/authProvider'
import useBackendService from '../../../../../config/services/backend-service'
import { useNavigate } from 'react-router-dom'
import Modal from '../../../SharedComponents/Modal/Modal'

const PersonalInformation = () => {
  const navigate = useNavigate()
  const { user } = useAuth()
  const [isModalOpen, setModalOpen] = useState(false)
  const [modal, setModal] = useState({
    type: '',
    title: '',
    desc: '',
    link: '',
    visible: false // Add a visible property for modal
  })

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      phoneNumber: user?.phoneNumber || ''
    }
  })

  const { mutate: updateinfo } = useBackendService(
    `/users/update_id/${user.id}`,
    'put',
    {
      onSuccess: response => {
        setModal({
          type: 'success',
          title: 'User details updated',
          desc: 'You have successfully updated the user details.',
          link: '/profile',
          visible: true
        })
        setModalOpen(true)
        navigate('/profile')
      },
      onError: error => {
        setModal({
          type: 'error',
          title: 'Unable to update user details',
          desc: 'There was an issue updating the user details. Please try again.',
          visible: true
        })
        setModalOpen(true)
      }
    }
  )

  const onSubmit = async data => {
    updateinfo(data)
  }

  const fullName = `${user.first_name} ${user.last_name}`.trim()
  const email = user.email
  const phoneNumber = user.phone_number

  return (
    <div className='border border-gray-300 rounded-md w-full h-full'>
      <h1 className='font-medium p-3'>Personal Information</h1>
      <hr />
      <form
        className='lg:w-[80%] p-3 space-y-3'
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextInput
          text='First Name'
          name='firstName'
          register={register}
          value={user?.firstName || ''}
          errors={errors}
          edit='true'
        />
        <TextInput
          text='Last Name'
          name='lastName'
          register={register}
          value={fullName}
          errors={errors}
          edit='true'
        />
        <TextInput
          text='Email'
          name='email'
          register={register}
          value={email}
          errors={errors}
          edit='true'
        />
        <TextInput
          text='Phone Number'
          name='phoneNumber'
          register={register}
          value={phoneNumber}
          errors={errors}
          edit='true'
        />

        <button
          type='submit'
          className='w-full h-[50px] bg-darkGreen text-white rounded-md ts'
        >
          Save Changes
        </button>
      </form>
      {modal.visible && (
        <Modal
          type={modal.type}
          title={modal.title}
          desc={modal.desc}
          link={modal.link}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </div>
  )
}

export default PersonalInformation
