import React from 'react'
import './Index.css'
import { ChevronRight } from 'lucide-react'

function TipsSection ({ data }) {
  const tips = data?.tips || []

  return (
    <section className=' flex flex-col gap-[30px] px-5 lg:px-10 '>
      <div className=' section-header '>
        <div className=' flex flex-col gap-4 '>
          <p className=' text-[18px] font-[600] text-black '>Tips for you</p>
          <p className=' text-grey hidden lg:flex font-[400] text-[14px] '>
            See suggestions on how to manage your business
          </p>
        </div>
        <div className=' tips-nav '>
          <p className=' text-[18px] font-[700]  '>Upgrade to see more</p>
          <ChevronRight size={22} />
        </div>
      </div>
      <div className=' flex flex-row overflow-x-auto lg:flex-1 gap-[15px] lg:gap-[30px] '>
        {tips.map(item => (
          <div className=' tips-ctn' key={item.id}>
            <div className='flex w-[260px] lg:w-full p-[15px] lg:p-5 gap-2 lg:gap-5'>
              <div className=' bg-primary lg:mt-[6px] w-8 h-[8px] lg:h-[10px] rounded-full ' />
              <div className=' flex flex-col gap-2 lg:gap-5  '>
                <div>
                  <p className='text-black font-[600] text-[14px] lg:text-[18px] '>
                    {item.title}
                  </p>
                  <div className=' font-[500] text-grey text-[12px] lg:text-[16px] '>
                    <p>{item.message}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default TipsSection
