import React from 'react'

const EmailInput = ({ register, errors, text }) => {
  return (
    <div className='grid w-full  items-center gap-1.5'>
      <label htmlFor='email' className='ts'>Email{text || ''}</label>
      <input
        type='email'
        name='email'
        {...register('email', { required: 'Email is required' })}
        placeholder='Email'
        className='email-input ts'
      />
      {errors.email && <p className='error-text'>{errors.email.message}</p>}
    </div>
  )
}

export default EmailInput
