import React from 'react'
import { ConfigProvider, Select } from 'antd'

const App = ({
  control,
  Controller,
  data,
  title,
  name,
  subtext,
  placeholder = 'Select a state',
  disabled,
  value,
  style,
  onChange
}) => {
  return (
    <div className='grid w-full  items-center gap-1.5'>
      <label htmlFor='email' className={`ts ${style}`}>{title}</label>
      {subtext && (
        <p className='text-[#888888] text-[16px] font-[400] ts '>{subtext}</p>
      )}
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#144618'
          }
        }}
      >
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <Select
              {...field}
              showSearch
              style={{
                width: '100%',
                height: '50px',
                border: '1px solid #F3F5F6',
                borderRadius: '8px',
                padding: '0px'
              }}
              placeholder={placeholder}
              optionFilterProp='label'
              options={data}
              disabled={disabled}
              defaultValue={value || ''}
              onChange={(value) => {
                field.onChange(value) // Update form state
                onChange && onChange(value) // Call the onChange prop
              }}
            />
          )}
        />
      </ConfigProvider>
    </div>
  )
}
export default App
