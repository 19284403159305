import { ArrowLeft } from 'lucide-react'
import React from 'react'

function formatDate (dateString) {
  const date = new Date(dateString)
  const options = { day: 'numeric', month: 'long', year: 'numeric' }
  return date.toLocaleDateString('en-GB', options)
}

function InvestInformationContainer ({ item }) {
  const length = item.userInvestmentData?.length || 0
  const totalSlots = item.available_slot || 1
  const percentageCompleted = (length / totalSlots) * 100
  return (
    <main className='flex flex-col gap-[15px] lg:gap-[30px]'>
      <ArrowLeft size={24} />
      <div className='flex flex-col lg:flex-row gap-5 lg:gap-10 flex-1'>
        <div className='flex flex-col gap-[30px] flex-[0.5]'>
          <div className='lg:w-full rounded-lg overflow-hidden'>
            <img alt='img' src={item.image_url} className='w-full h-full' />
          </div>
          <div className='flex justify-between items-center'>
            <div className='flex flex-col gap-[15px]'>
              <p className='text-[14px] lg:text-[18px] font-[500] text-black'>
                {item.title}
              </p>
              <p className='text-[12px] lg:text-[14px] text-grey font-[600]'>
                {/* By Investor */}
              </p>
            </div>
            <div className='flex flex-col gap-[15px]'>
              <p className='text-[16px] lg:text-[20px] font-[500] text-primary'>
                {item.price}
              </p>
              <p className='text-[12px] lg:text-[14px] text-grey font-[600]'>
                /units
              </p>
            </div>
          </div>
          <div className='w-full bg-primary p-3 lg:py-4 rounded-lg lg:px-5 flex items-center justify-center'>
            <p className='font-[600] text-[14px] lg:text-[16px]'>Invest now</p>
          </div>
          <div className='flex flex-col gap-5 w-full'>
            <p className='text-center font-[600] text-[12px] lg:text-[14px] text-grey'>
              There are still some units left to purchase
            </p>
            <div className='w-full rounded-lg bg-[#DDDDDD] h-[10px]'>
              <div
                className='bg-darkGreen h-full'
                style={{ width: `${percentageCompleted}%` }}
              />
            </div>
            <div className='flex self-center justify-center items-center py-3 px-5 rounded-[30px] bg-[#E7ECE8]'>
              <p className='text-[14px] lg:text-[16px] font-[400] text-primary'>
                {percentageCompleted.toFixed(2)}% of units available
              </p>
            </div>
          </div>
          <div className='flex flex-col gap-5'>
            <p className='font-[500] text-[14px] lg:text-[18px] text-black'>
              About
            </p>
            <p className='text-[#444444] text-[14px] lg:text-[16px] font-[500]'>
              {item.about}
            </p>
          </div>
          <div className='rounded-lg px-5 hidden lg:flex py-[30px] flex-col gap-5 bg-[#fdf4f5]'>
            <p className='text-[#D92534] font-[600] text-[14px] lg:text-[16px]'>
              Notice
            </p>
            <p className='text-[#D92534] font-[500] text-[14px] lg:text-[16px]'>
              T-Gaims does not provide investment advise and individual
              investors should make their own decisions or seek independent
              advice
            </p>
          </div>
        </div>
        <div className='flex flex-col gap-[20px] lg:gap-[50px] flex-[0.5]'>
          <p className='font-[600] text-[14px] lg:text-[18px] text-black'>
            Investment Information
          </p>
          <div className='gap-5 grid grid-cols-2'>
            <div className='flex flex-col gap-2 border border-[#EEEEEE] rounded-lg flex-1 px-5 py-[15px] font-[600]'>
              <p className='text-black text-[12px] lg:text-[14px]'>
                Investment Type
              </p>
              <p className='text-primary text-[14px] lg:text-[16px]'>
                {item.investment_type}
              </p>
            </div>
            <div className='flex flex-col gap-2 border border-[#EEEEEE] rounded-lg flex-1 px-5 py-[15px] font-[600]'>
              <p className='text-black text-[12px] lg:text-[14px]'>
                Start date
              </p>
              <p className='text-primary text-[14px] lg:text-[16px]'>
                {formatDate(item.start_date)}
              </p>
            </div>
            <div className='flex flex-col gap-2 border border-[#EEEEEE] rounded-lg flex-1 px-5 py-[15px] font-[600]'>
              <p className='text-black text-[12px] lg:text-[14px]'>
                Offer closing Date
              </p>
              <p className='text-primary text-[14px] lg:text-[16px]'>
                {formatDate(item.end_date)}
              </p>
            </div>
            <div className='flex flex-col gap-2 border border-[#EEEEEE] rounded-lg flex-1 px-5 py-[15px] font-[600]'>
              <p className='text-black text-[12px] lg:text-[14px]'>
                Maturity date
              </p>
              <p className='text-primary text-[14px] lg:text-[16px]'>
                {formatDate(item.payout_date)}
              </p>
            </div>

            <div className='flex flex-col gap-2 border border-[#EEEEEE] rounded-lg flex-1 px-5 py-[15px] font-[600]'>
              <p className='text-black text-[12px] lg:text-[14px]'>
                Payout type
              </p>
              <p className='text-primary text-[14px] lg:text-[16px]'>
                Capital + Profit
              </p>
            </div>
          </div>
          <div className='flex flex-col flex-1 py-5 bg-darkGreen rounded-lg'>
            <div className='pb-[15px] border-b border-b-[#AAAAAA40] px-5'>
              <p className='text-white font-[600] text-[14px] lg:text-[16px]'>
                More information
              </p>
            </div>
            <div className='flex py-5 flex-col gap-4 pb-[15px] border-b border-b-[#AAAAAA40] px-5'>
              <p className='text-white font-[600] text-[14px] lg:text-[16px]'>
                Repayment plan
              </p>
              <p className='text-white font-[500] leading-5 text-[14px] lg:text-[16px]'>
                {item.repayment_plan}
              </p>
            </div>
            <div className='flex py-5 flex-col gap-4 pb-[15px] leading-5 px-5'>
              <p className='text-white font-[600] text-[14px] lg:text-[16px]'>
                Investment risk
              </p>
              <p className='text-white font-[500] text-[16px] leading-[36px] lg:text-[18px]'>
                {item.investment_risk}
              </p>
            </div>
          </div>
        </div>
        <div className='rounded-lg px-5 lg:hidden py-[30px] flex border border-[#D92534] flex-col gap-5 bg-[#fdf4f5]'>
          <p className='text-[#D92534] font-[600] text-[14px] lg:text-[16px]'>
            Notice
          </p>
          <p className='text-[#D92534] font-[500] text-[14px] lg:text-[16px]'>
            T-Gaims does not provide investment advise and individual investors
            should make their own decisions or seek independent advice
          </p>
        </div>
      </div>
    </main>
  )
}

export default InvestInformationContainer
