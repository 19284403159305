import React from 'react'
import { Eye, EyeOff } from 'lucide-react'

const SinglePasswordInput = ({ register, errors, setShowPassword, showPassword }) => {
  return (
    <div className='input-container space-y-2 ts'>
      <label htmlFor='password'>Password</label>
      <div className='input-cover'>

        <input
          type={showPassword ? 'text' : 'password'} {...register('password', {
            required: 'Password is required',
            minLength: {
              value: 8,
              message: 'Password must be at least 8 characters'
            },
            validate: (value) => {
              return (
                [/[a-z]/, /[A-Z]/, /[^a-zA-Z0-9]/].every((pattern) =>
                  pattern.test(value)
                ) || 'must include lower, upper, and special character'
              )
            }

          })} name='password' className='email-input ts'
        />
        <div onClick={() => setShowPassword(!showPassword)} className='show-btn'>{showPassword ? <EyeOff /> : <Eye />}</div>
      </div>
      {errors.password && (
        <p className='error-text'>{errors.password.message}</p>
      )}

    </div>
  )
}

export default SinglePasswordInput
