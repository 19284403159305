import React, { useState } from 'react'
import './Index.css'
import { useDispatch } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import useBackendService from '../../../../config/services/backend-service'
import { logout } from '../../../../config/store/reducers/authReducer'
import SideBar from '../SideBar/SideBar'
import { LogOut, X } from 'lucide-react'
import NavBar from '../NavBar/NavBar'
import { RegularDashboardContent } from '../../../data/static'
import MobileSidebar from '../MobileSidebar/MobileSidebar'
import { useAuth } from '../../../../config/contexts/authProvider'

const RegularLayoutWrapper = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user } = useAuth()

  const [visible, setVisible] = useState(false)

  const { mutate } = useBackendService('/auth/logout', 'post', {
    onSuccess: () => {
      dispatch(logout())
      navigate('/')
    },
    onError: error => {
      console.error('Logout failed:', error)
    }
  })
  const handleLogout = () => {
    mutate()
  }

  const handleClick = () => {
    setVisible(true)
  }

  return (
    <div className='w-full flex max'>
      {visible === true && (
        <div className=' fixed h-full flex justify-end w-screen lg:hidden z-10 bg-black/30 left-0 top-0 '>
          <section className=' bg-white w-[280px] flex flex-col items-start  h-screen py-8 gap-8 z-10 '>
            <div className=' w-full flex px-[20px] flex-row justify-between items-center '>
              <div className='' onClick={() => setVisible(false)}>
                <img
                  src={require('../../../resources/logoleaf.png')}
                  alt=''
                  className=' w-[98px] '
                />
              </div>
              <div className='' onClick={() => setVisible(false)}>
                <X size={20} />
              </div>
            </div>
            <div className=' w-full gap-5 flex flex-col '>
              {RegularDashboardContent.map((menu, index) => (
                <MobileSidebar
                  key={menu.link}
                  name={menu.name}
                  icon={menu.icon}
                  link={menu.link}
                  orange={menu.orange}
                />
              ))}
            </div>
            {user.plan !== 'Pro' &&
              <div className=' px-[30px] '>
                <div className=' rounded-lg bg-darkGreen flex flex-col gap-6  p-5 '>
                  <p className=' text-[14px] font-[500] text-white text-center '>
                    Upgrade to get access to more features
                  </p>
                  <div className=' bg-white w-full h-[50px] rounded-lg flex items-center justify-center '>
                    <p className=' text-primary text-[12px] font-[600]  '>
                      Upgrade plan
                    </p>
                  </div>
                </div>
              </div>}
            <div className='flex-[1] justify-end flex flex-col p-3 items-end '>
              <div
                className=' text-red-400 gap-2 flex  ml-3'
                onClick={handleLogout}
              >
                <LogOut color='red' size={20} />
                Logout
              </div>
            </div>
          </section>
        </div>
      )}
      <div className='sidebar-wrapper'>
        <div className='w-full flex flex-col justify-center items-start'>
          <div className='logo-cover px-10'>
            <img
              alt='logo'
              src={require('../../../resources/logo-white.png')}
              className=' w-[80px] lg:w-[100px]'
            />
          </div>
          <div className='mt-[20%] w-full'>
            {RegularDashboardContent.map(menu => (
              <SideBar
                key={menu.link}
                name={menu.name}
                icon={menu.icon}
                link={menu.link}
                orange={menu.orange}
              />
            ))}
          </div>
        </div>
        {user.plan !== 'Pro' &&
          <div className=' flex flex-col  w-[203px] rounded-lg gap-5 p-5 bg-darkGreen '>
            <p className=' font-[400] text-[18px] text-center text-white '>
              Upgrade to get access to more features
            </p>
            <div className='py-4 px-5 cursor-pointer bg-white flex items-center justify-center rounded-[30px] '>
              <p className=' text-[14px] font-[500] text-primary '>
                Upgrade plan
              </p>
            </div>
          </div>}
        <button className='logout-button-style ts' onClick={handleLogout}>
          <LogOut />
          Logout
        </button>
      </div>

      <div className='topbar-wrapper '>
        <NavBar location={useLocation()} onClick={handleClick} />
        <div className=' w-full bg-gray-50 '>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default RegularLayoutWrapper
