import React, { useState } from 'react'
import { Steps } from 'antd'
import { ChevronLeft } from 'lucide-react'
import BackButton from '../SharedComponents/BackButton/BackButton'

const NavigationSteps = ({ First, Second, Third }) => {
  const [current, setCurrent] = useState(0)
  const [submitted, setSubmitted] = useState(false)
  const [formData, setFormData] = useState({})
  const [sucess, setSucess] = useState(false)

  const next = () => {
    setCurrent(current + 1)
  }
  const prev = () => {
    setCurrent(current - 1)
  }

  const steps = [
    {
      content: <First next={next} setFormData={setFormData} />
    },
    Second
      ? {
          content: <Second next={next} setFormData={setFormData} />
        }
      : null,
    Third
      ? {
          content: (
            <Third
              setSucess={setSucess}
              sucess={sucess}
              submitted={submitted}
              setSubmitted={setSubmitted}
              formData={formData}
            />
          )
        }
      : null
  ].filter(Boolean)

  // Map steps to items for the Steps component
  const items = steps.map((item, index) => ({
    key: index, // Unique key for each step
    title: `Step ${index + 1}` // Placeholder title; adjust as needed
  }))

  const contentStyle = {
    marginTop: 16
  }

  return (
    <div className='w-full'>
      {submitted || sucess
        ? (
            ''
          )
        : (
          <div className='w-full flex justify-start items-center gap-4'>
            {current > 0
              ? (
                <div className=' lg:w-[25%]'>
                  <ChevronLeft
                    className='text-darkgreen cursor-pointer'
                    onClick={prev}
                  />
                </div>
                )
              : (
                <BackButton />
                )}
            <Steps
              current={current}
              items={items}
              direction='horizontal'
              responsive={false}
              className='lg:w-[80%] flex w-[80%] mx-auto lg:mx-0 horizontal-steps'
            />
          </div>
          )}
      <div style={contentStyle}>{steps[current].content}</div>
    </div>
  )
}

export default NavigationSteps
