import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { ArrowLeft } from 'lucide-react'
import TextInput from '../../../SharedComponents/TextInput/TextInput'
import SelectInput from '../../../SharedComponents/SelectInput/SelectInput'
import SinglePasswordInput from '../../../SharedComponents/SinglePasswordInput/SinglePasswordInput'
import EmailInput from '../../../SharedComponents/EmailInput/EmailInput'
import useBackendService from '../../../../../config/services/backend-service'
import { useAuth } from '../../../../../config/contexts/authProvider'
import { useNavigate } from 'react-router-dom'
import Modal from '../../../SharedComponents/Modal/Modal'
import { statesInNigeria } from '../../../../data/static'

const AddNewFarmPage = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const [modal, setModal] = useState({
    type: '',
    title: '',
    desc: '',
    link: '',
    visible: false // Add a visible property for modal
  })
  const [farmId, setFarmId] = useState(null)
  const [adminData, setAdminData] = useState(null)
  const { user } = useAuth()
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid }
  } = useForm()

  const { mutate: createFarm } = useBackendService('/farms', 'post', {
    onSuccess: data => {
      setFarmId(data.data.id)
      setModal({
        type: 'success',
        title: 'Farm successfully created',
        desc: 'Well done , you have successfully submitted a new farm.',
        link: '/farms',
        visible: true
      })
      setModalOpen(true)
    },
    onError: () => {
      setModal({
        type: 'error',
        title: 'Unable to Create a farm',
        desc: 'You are unable to create a farm at the moment.',
        visible: true

      })
      setModalOpen(true)
    }
  })

  const { mutate: createUser } = useBackendService('/users', 'post', {
    onSuccess: () => {
      navigate('/farms')
    },
    onError: (error) => {
      console.log(error)
    }
  })

  const onSubmit = async data => {
    setAdminData({
      firstName: data.firstName,
      lastName: data.lastName,
      isAdmin: false,
      location: data.location,
      plan: 'Enterprise',
      phoneNumber: data.phoneNumber,
      role: 'administrator',
      email: data.email,
      password: data.password
    })
    // Submit the farm data
    createFarm({ ...data, parentFarmId: user.farmId })
  }

  useEffect(() => {
    if (farmId && adminData) {
      createUser({ ...adminData, farmId })
    }
  }, [farmId, adminData, createUser])

  return (
    <div className='farmwrapper'>
      <a href='/farms' className='w-full'>
        <ArrowLeft size={20} />
      </a>
      <div className='space-y-0 lg:space-y-1'>
        <h1 className='lg:text-3xl text-xl text-black'>Add new branch</h1>
        <p className='text-[#666666] text-sm lg:text-sm'>
          Fill in the details of your new branch
        </p>
      </div>
      <form className='form-wrapper' onSubmit={handleSubmit(onSubmit)}>
        <div className='w-full flex flex-col lg:flex-row gap-2 '>
          <TextInput
            register={register}
            text='Branch'
            name='name'
            errors={errors}
          />
          <SelectInput
            register={register}
            control={control}
            Controller={Controller}
            title='State'
            data={statesInNigeria}
            name='state'
          />
        </div>
        <div className='w-full lg:w-[50%] '>
          <TextInput
            register={register}
            name='address'
            text='Address'
            errors={errors}
          />
        </div>
        <div className='space-y-0 lg:space-y-1'>
          <h1 className='lg:text-3xl text-xl text-black'>
            Assign admin <span className='text-darkGreen'>(optional)</span>
          </h1>
          <p className='text-[#666666] text-sm lg:text-sm'>
            Fill in the details of the branch admin
          </p>
        </div>
        <div className='w-full flex flex-col lg:flex-row gap-2 '>
          <div className='w-full lg:w-[50%]'>
            <TextInput
              register={register}
              name='firstName'
              text='First Name'
              errors={errors}
            />
          </div>
          <div className='w-full lg:w-[50%]'>
            <TextInput
              register={register}
              name='lastName'
              text='Last Name'
              errors={errors}
            />
          </div>
        </div>
        <div className='w-full flex flex-col lg:flex-row gap-2 '>
          <div className='w-full lg:w-[50%]'>
            <TextInput
              register={register}
              name='phoneNumber'
              text='Phone Number'
              errors={errors}
            />
          </div>
          <div className='w-full lg:w-[50%]'>
            <TextInput
              register={register}
              name='location'
              text='Address'
              errors={errors}
            />
          </div>
        </div>
        <div className='w-full flex flex-col lg:flex-row gap-2 '>
          <div className='w-full lg:w-[50%]'>
            <EmailInput register={register} errors={errors} />
          </div>
          <div className='w-full lg:w-[50%]'>
            <SinglePasswordInput
              register={register}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
              errors={errors}
            />
          </div>
        </div>
        <button
          type='submit'
          className={`w-full lg:w-[50%] h-[50px] ts ${
            isDirty && isValid
              ? 'proceed-btn bg-darkGreen'
              : 'rounded-[8px] bg-darkash text-black'
          }`}

        >
          Create new branch
        </button>
      </form>
      {/* Conditionally render the modal */}
      {modal.visible && (
        <Modal
          type={modal.type}
          title={modal.title}
          desc={modal.desc}
          link={modal.link}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </div>
  )
}

export default AddNewFarmPage
