import React from 'react'
import DataTable from '../../EnterpriseApp/FarmPage/DataTable/DataTable'
import { columns } from '../StockTableColumn/StockTableColumn'
import EmptyDataContent from '../../SharedComponents/EmptyDataContent/EmptyDataContent'
import EmptyFarmImage from '../../../resources/farmemptyimage.png'
import useWindowSize from '../../EnterpriseApp/UseWindowSize/UseWindowSize'
import { StockMobileColumns } from '../StockTableColumn/StockMobileColumn/StockMobileColumn'

const StockDataTable = ({ data }) => {
  const { width } = useWindowSize()
  const columnsforstock = width > 1024 ? columns : StockMobileColumns
  return (
    <div className='w-full bg-[#F9F9F9] rounded-md h-full px-[2%] py-[1%]'>
      <DataTable
        data={data}
        columns={columnsforstock}
        stockfilter='true'
        pagination='true'
        nav='true'
        emptydatacontent={
          <EmptyDataContent
            image={EmptyFarmImage}
            text='No stock yet'
            desc='Once you add stocks you would get stock overview here.'
          />
        }
      />
    </div>
  )
}

export default StockDataTable
