import { capitalizeWords } from '../../../../../../config/services/capitalize'

export const OperationMobileColumns = [
  {
    accessorKey: 'title',
    header: 'Title',
    cell: context => {
      const value = context.getValue()
      return <div className='w-[100px] ts'>{capitalizeWords(value)}</div>
    }
  },
  {
    accessorKey: 'type',
    header: 'Type',
    cell: context => {
      const value = context.getValue()
      return <div className='w-[100px] ts'>{capitalizeWords(value)}</div>
    }
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: context => {
      const value = context.getValue()
      return (
        <span
          className='ts'
          style={{
            color:
              value === 'Rejected'
                ? 'red'
                : value === 'Acknowledged'
                  ? 'green'
                  : '#F29339'
          }}
        >
          {capitalizeWords(value)}
        </span>
      )
    }
  }
]
