import { MoreHorizontal } from 'lucide-react'
import { capitalizeWords } from '../../../../../config/services/capitalize'

export const ProductColumns = [
  {
    accessorKey: 'name',
    header: 'Name',
    cell: context => {
      const value = context.getValue()
      return <div className='w-[100px] ts'>{capitalizeWords(value)}</div>
    }
  },

  {
    accessorKey: 'active',
    header: 'Status',
    cell: context => {
      const value = context.getValue()
      return (
        <span
          className='ts'
          style={{ color: value === false ? 'red' : 'green' }}
        >
          {value === true ? 'Active' : 'Inactive'}
        </span>
      )
    }
  },
  {
    accessorKey: 'unit_value',
    header: 'Stock',
    cell: context => {
      const value = context.getValue()
      return <div className='ts'>{value}</div>
    }
  },

  {
    accessorKey: 'action',
    header: 'Action',

    enableHiding: false,
    cell: () => {
      return <MoreHorizontal className='h-4 w-4' />
    }
  }
]
