import React from 'react'
import './Index.css'
import PayWall from '../../PayWall/PayWall'
import { useAuth } from '../../../../../config/contexts/authProvider'
import { ChevronRight } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

function SalesSection ({ data }) {
  const { user } = useAuth()
  const products = data?.products || []
  const navigate = useNavigate()

  const handleNavigate = id => {
    navigate(`/sales/${id}`)
  }

  console.log(products)
  return (
    <>
      {user.plan !== 'Pro'
        ? (
          <section className=' px-5 lg:px-10 flex flex-col gap-[15px] lg:gap-[30px]  '>
            <div className=' flex flex-row justify-between items-center '>
              <div className=' flex flex-col gap-4 '>
                <p className=' text-[14px] lg:text-[18px] gap-1 flex font-[600] text-black '>
                  Sales & Supply{' '}
                  <span className='hidden lg:flex'> chain information</span>
                </p>
                <p className=' text-grey lg:flex hidden font-[400] text-[14px] '>
                  Stay ahead with the latest market trends
                </p>
              </div>
              <p className=' font-[700] text-primary lg:text-[16px] text-[12px] '>
                Upgrade to view
              </p>
            </div>
            <div className='basic-sales-content '>
              <PayWall text='Upgrade to the Pro plan to access detailed packaging options, market trends and current market price' />
            </div>
          </section>
          )
        : (
          <section className=' px-5 lg:px-10 flex flex-col gap-[30px]  '>
            <div className=' section-header '>
              <div className=' flex flex-col gap-4 '>
                <p className='  text-[14px] flex flex-row gap-1 items-center lg:text-[18px] font-[600] text-black '>
                  Sales & Supply chain
                  <span className=' lg:flex hidden'>information</span>
                </p>
                <p className=' text-grey lg:flex hidden font-[400] text-[14px] '>
                  Stay ahead with the latest market trends
                </p>
              </div>
              <div className=' sales-nav '>
                <p className=' text-[14px] lg:text-[18px] font-[700]  '>
                  See more
                </p>
                <ChevronRight size={22} />
              </div>
            </div>
            <div className=' flex flex-row overflow-x-auto flex-1 gap-5 lg:gap-[45px] '>
              {products.map(item => (
                <div className=' sales-content-ctn' key={item.id}>
                  <div className=' lg:w-full w-[260px] flex flex-col p-2 lg:p-5 gap-2 lg:gap-5'>
                    <p className='text-black font-[600] text-[14px] lg:text-[18px] '>
                      {item.name}
                    </p>
                    <img
                      src={item.image_url}
                      alt=''
                      className='w-full h-[200px] rounded-lg overflow-hidden '
                    />
                    <div className=' flex flex-1 justify-end flex-col '>
                      <div
                        className=' flex cursor-pointer items-center flex-row gap-[10px] text-primary'
                        onClick={() => handleNavigate(item.id)}
                      >
                        <p className=' text-[14px]  lg:text-[18px] font-[700]  '>
                          Check out
                        </p>
                        <ChevronRight size={22} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
          )}
    </>
  )
}

export default SalesSection
