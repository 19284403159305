import React from 'react'

const FrequentlyAskQuestionSection = () => {
  return (
    <section className='flex flex-col gap-10 items-center px-[5%] pt-10 lg:py-[80px]'>
      <div className=' flex flex-col gap-3 text-start lg:text-center '>
        <p className=' font-[600] text-[18px] lg:text-[30px] text-primary '>
          Frequently Asked Questions
        </p>
        <p className=' text-grey lg:text-[16px] text-[13px] text-start lg:text-center '>
          Whether you're overseeing agriculture investment portfolios, farms
          (large, medium or small <br className=' hidden lg:flex ' /> scale
          entreprise), our features ensure you have everything you need to
          succeed.
        </p>
      </div>
      <div className=' flex flex-col lg:flex-row  gap-4 lg:gap-10 '>
        <div className=' flex flex-col lg:flex-[0.5] min-h-full  gap-4 justify-between'>
          <div className=' flex flex-row w-[full]  lg:flex-[0.5] bg-[#F7F7F7] gap-4 rounded-lg p-[15px] lg:p-[30px]  '>
            <img
              src={require('../../../assets/question.png')}
              className=' w-6 lg:w-9 h-6 lg:h-9 '
              alt=''
            />
            <div className=' flex flex-col gap-1 lg:gap-3 '>
              <p className=' text-[14px] lg:text-[18px] text-black font-[500] w-full '>
                Can I use T-Gaims if I don't have a farm?
              </p>
              <p className='text-grey lg:leading-[30px] lg:text-[16px] text-[13px] '>
                Absolutely! Engaging with our agricultural website offers a
                multitude of benefits beyond farm ownership. Whether you're
                interested in gardening at home, supporting local farmers,
                learning about sustainable practices, or connecting with a
                community passionate about agriculture, our platform provides
                valuable resources, knowledge, and opportunities to enrich
                your agricultural journey
              </p>
            </div>
          </div>
        </div>
        <div className=' flex flex-col lg:flex-[0.5] gap-4 justify-between'>
          <div className=' flex flex-row w-[full] lg:flex-[0.5] bg-[#F7F7F7] gap-4 rounded-lg p-[15px] lg:p-[30px]  '>
            <img
              src={require('../../../assets/question.png')}
              className='w-6 lg:w-9 h-6 lg:h-9'
              alt=''
            />
            <div className=' flex flex-col gap-1 lg:gap-3 '>
              <p className=' text-[14px] lg:text-[18px] text-black font-[500] '>
                Why should I subscribe to T-Gaims?
              </p>
              <p className=' text-grey lg:leading-[30px] lg:text-[16px] text-[13px] '>
                Subscribing to TGAIM provides access to industry insights,
                networking opportunities with professionals in agriculture,
                educational resources to enhance skills, potential business
                collaborations, and active community engagement. It's a
                valuable resource for staying informed, expanding professional
                networks, and exploring opportunities within the agricultural
                sector and related fields.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className=' flex flex-col lg:flex-row  gap-10 '>
        <div className=' flex flex-col lg:flex-[0.5] gap-4 justify-between min-h-ful '>
          <div className=' flex flex-row w-[full] min-h-full lg:flex-[0.5] bg-[#F7F7F7] gap-4 rounded-lg p-[15px] lg:p-[30px]  '>
            <img
              src={require('../../../assets/question.png')}
              className='w-6 lg:w-9 h-6 lg:h-9'
              alt=''
            />
            <div className=' flex flex-col gap-1 lg:gap-3 '>
              <p className='text-[14px] lg:text-[18px] text-black font-[500]  '>
                How many of my businesses can I register on T-Gaims?
              </p>
              <p className='text-grey lg:leading-[30px] lg:text-[16px] text-[13px] '>
                As many Agribusinesses as you own. You shall always find
                T-Gaims useful for all Agribusiness value chains and
                Agricultural investments.
              </p>
            </div>
          </div>
        </div>
        <div className=' flex flex-col gap-4 min-h-full lg:flex-[0.5]  justify-between'>
          <div className=' flex flex-row w-[full] min-h-full lg:flex-[0.5] bg-[#F7F7F7] gap-4 rounded-lg p-[15px] lg:p-[30px]  '>
            <img
              src={require('../../../assets/question.png')}
              className='w-6 lg:w-9 h-6 lg:h-9'
              alt=''
            />
            <div className=' flex flex-col gap-1 lg:gap-3 '>
              <p className='text-[14px] lg:text-[18px] text-black font-[500] '>
                Who can use T-Gaims
              </p>
              <p className='text-grey lg:leading-[30px] lg:text-[16px] text-[13px]'>
                Whether you're overseeing a large-scale enterprise or a small
                farm, our features ensure you have everything you need to
                succeed.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className=' flex flex-col lg:flex-row lg:justify-center justify-start  gap-10 '>
        <div className=' flex flex-col lg:flex-[0.5] h-[250px] gap-4 justify-between'>
          <div className=' flex flex-row w-[full] lg:flex-[0.5]  bg-[#F7F7F7] gap-4 rounded-lg p-[15px] lg:p-[30px]  '>
            <img
              src={require('../../../assets/question.png')}
              className='w-6 lg:w-9 h-6 lg:h-9'
              alt=''
            />
            <div className=' flex flex-col gap-1 lg:gap-3 '>
              <p className='text-[14px] lg:text-[18px] text-black font-[500]  '>
                What is T-Gaims?
              </p>
              <p className='text-grey lg:leading-[30px] lg:text-[16px] text-[13px]'>
                T-Gaims is your one-stop shop for all Agricultural matters
                (Agri-investment, Agri-Management, Agri-Business, Agriculture
                News, and Agri-games)
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FrequentlyAskQuestionSection
