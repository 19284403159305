import React, { useState } from 'react'
import '../../SharedComponents/Otp/Otp.css'
import Logo from '../../../resources/logo-white.png'
import OtpInput from 'react-otp-input'
import Image from '../../../resources/wateryplant.png'
import { useNavigate } from 'react-router-dom'
import BackButton from '../../SharedComponents/BackButton/BackButton'
import useBackendService from '../../../../config/services/backend-service'
import useWindowSize from '../../EnterpriseApp/UseWindowSize/UseWindowSize'

const ForgetPasswordOtp = () => {
  const { width } = useWindowSize()
  const isTabletView = width >= 768 && width <= 1024
  const [otp, setOtp] = useState('')
  const navigate = useNavigate()
  const [email, setEmail] = useState('')

  const { mutate: verifyOtp, isLoading: verifying } = useBackendService(
    '/verify_otp',
    'post',
    {
      onSuccess: () => navigate('/createpassword', { state: { email } }),
      onError: error =>
        console.error(error.response?.data?.message || 'Error occurred')
    }
  )

  const { mutate: resendOtp, isLoading: resending } = useBackendService(
    '/resend_otp',
    'post',
    {
      onSuccess: () => console.log('OTP resent successfully'),
      onError: error =>
        console.error(error.response?.data?.message || 'Error occurred')
    }
  )

  const handleClick = e => {
    e.preventDefault()
    verifyOtp({ otp })
  }

  const handleResendOtp = () => {
    resendOtp({ email })
  }
  const isButtonDisabled = !otp || !email
  return (
    <>
      {isTabletView ? (
        <div className='w-full h-screen  bg-waterplant bg-cover p-[5%]'>
          <div className='center w-full'>
            <img alt='' src={Logo} className='w-[100px]' />
          </div>
          <div className='w-full center flex-col text-white text-center space-y-2 mt-[2%]'>
            <h1 className='text-2xl  w-full'>
              Manage your agribusiness efficiently
            </h1>
            <p className='w-[80%] leading-[16px] font-[400] text-[16px]'>
              Building the best and more efficient platform for agribusiness
              management.
            </p>
          </div>
          <div className='w-[90%] p-5 bg-white mt-[4%] mx-auto rounded-md'>
            <div className='right-content-wrapper z-40 bg-white rounded-t-[40px] '>
              <BackButton />
              <div className='text-header'>
                <p className='first-text text-darkgreen '>Enter Reset Code</p>
                <p className='small-text'>
                  Kindly enter the code that was sent to your email.
                </p>
              </div>

              <div className='email-wrapper'>
                <input
                  type='email'
                  className='email-input'
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
                <button
                  className='change-btn'
                  onClick={handleResendOtp}
                  disabled={resending}

                >
                  {resending ? 'Resending...' : 'Change'}
                </button>
              </div>

              <div className='otp-container '>
                <p className='otp-wrapper'>
                  Didn’t get a reset code? Click resend in{' '}
                  <span className='resend'>(0:59)</span>
                </p>
                <form action='' className=' w-full lg:w-[90%] h-full space-y-6'>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderInput={props => (
                      <input
                        {...props}
                        style={{
                          width: '50px',
                          height: '50px',
                          marginRight: '10px',
                          padding: '10px',
                          fontSize: '24px',
                          borderRadius: '5px',
                          border: '1px solid #ccc',
                          textAlign: 'center',
                          background: 'white'
                        }}
                      />
                    )}
                    containerStyle={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center'
                    }}
                    inputStyle={{
                      backgroundColor: 'white',
                      color: 'black'
                    }}
                    inputType='tel'
                  />

                  <button onClick={handleClick} type='submit' className={`${isButtonDisabled ? 'bg-[#DDDDDD] text-black w-full' : 'proceed-btn'} proceed-btn w-full`} disabled={isButtonDisabled}>
                    {verifying ? 'Verifying...' : 'Resend'}
                  </button>
                </form>
                <p className='login-text'>
                  Have an account? <span className='login-link'>Log in</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='wrapper'>
          {/* left image */}
          <div className='left-content '>

            <div className='absolute top-[5%] left-1/2 lg:left-[10%] transform -translate-x-1/2 -translate-y-1/2 center mx-auto'>
              <img alt='' src={Logo} className='w-[80px] lg:w-[100px]' />
            </div>

            <img alt='' src={Image} className='object-contain' />
            <div className='image-text-wrapper'>
              <h1 className='text-2xl lg:text-5xl w-full'>
                Manage your <br /> agribusiness efficiently
              </h1>
              <p className='w-[100%] lg:w-[70%] lg:text-lg leading-5 text-sm'>
                Building the best and more efficient platform for agribusiness
                management.
              </p>
            </div>
          </div>
          {/* right content */}
          <div className='right-content-wrapper z-40 bg-white rounded-t-[40px] '>
            <BackButton />
            <div className='text-header'>
              <p className='first-text text-darkgreen '>Enter Reset Code</p>
              <p className='small-text'>
                Kindly enter the code that was sent to your email.
              </p>
            </div>

            <div className='email-wrapper'>
              <input
                type='email'
                className='email-input'
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <button
                className='change-btn'
                onClick={handleResendOtp}
                disabled={resending}
              >
                {resending ? 'Resending...' : 'Change'}

              </button>
            </div>

            <div className='otp-container '>
              <p className='otp-wrapper'>
                Didn’t get a reset code? Click resend in{' '}
                <span className='resend'>(0:59)</span>
              </p>
              <form action='' className=' w-full lg:w-[100%] h-full space-y-6'>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderInput={props => (
                    <input
                      {...props}
                      style={{
                        width: '50px',
                        height: '50px',
                        marginRight: '10px',
                        padding: '10px',
                        fontSize: '24px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                        textAlign: 'center',
                        background: 'white'
                      }}
                    />
                  )}
                  containerStyle={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center'
                  }}
                  inputStyle={{
                    backgroundColor: 'white',
                    color: 'black'
                  }}
                  inputType='tel'
                />

                <button onClick={handleClick} type='submit' className={`${isButtonDisabled ? 'bg-[#DDDDDD] text-black w-full' : 'proceed-btn'} proceed-btn w-full`} disabled={isButtonDisabled}>
                  {verifying ? 'Verifying...' : 'Resend'}
                </button>
              </form>
              <p className='login-text'>
                Have an account?  <a href='/login' className='login-link'>
                  Log in
                </a>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ForgetPasswordOtp
