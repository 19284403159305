import { useState } from 'react'
import useBackendService from '../../../../config/services/backend-service'

const ProPlanEmailVerification = () => {
  const [email, setEmail] = useState('')

  const { mutate: resendVerification, isLoading } = useBackendService(
    '/resend_verification',
    'post',
    {
      onSuccess: data => {
        console.log('Verification link resent:', data)
      },
      onError: err => {
        console.error('Error resending verification link:', err)
      }
    }
  )

  const handleResend = e => {
    e.preventDefault()
    resendVerification({ email })
  }

  return (
    <div className='content-cover'>
      <div className='w-full  space-y-1'>
        <h1 className='header-text'>Verify your email address</h1>
        <p className='text-black w-[90%] mt-2 '>
          Click the verification link that was sent to the email address below
          to confirm your details.
        </p>
      </div>

      <form className='mt-[8%] w-full' onSubmit={handleResend}>
        <div className='email-wrapper'>
          <input
            type='email'
            className='email-input ts'
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <button className='change-btn'>Change</button>
        </div>
        <p className='text-[#4D5761] text-sm mt-2'>
          Didn’t get a verification link? Click resend in{' '}
          <span className='text-darkgreen font-bold'>(0.59)</span>
        </p>

        <button
          type='submit'
          className='activebutton mt-10 ts'
          disabled={isLoading}
        >
          {isLoading ? 'Resending...' : 'Resend'}
        </button>
      </form>

      <p className='login-text'>
        Have an account?{' '}
        <a href='/login' className='login-link'>
          Log in
        </a>
      </p>
    </div>
  )
}

export default ProPlanEmailVerification
