import React from 'react'
import './Index.css'
import { ChevronRight } from 'lucide-react'
import { Link } from 'react-router-dom'
import InvestContainer from '../../InvestContainer/InvestContainer'

function InvestmentSection ({ data }) {
  const investments = data?.investments || []

  return (
    <section className=' flex  flex-col gap-[15px] lg:gap-[30px] px-5 lg:px-10 '>
      <div className=' section-header '>
        <div className=' flex flex-col gap-4 '>
          <p className=' text-[14px] lg:text-[18px] font-[600] text-black '>
            Investment Plans
          </p>
          <p className=' hidden lg:flex text-grey font-[400] text-[14px] '>
            Check out investment plans for you
          </p>
        </div>
        <Link to='/invest'>
          <div className=' basic-nav '>
            <p className=' text-[14px] lg:text-[18px] font-[700]  '>See more</p>
            <ChevronRight size={22} />
          </div>
        </Link>
      </div>
      <div className=' flex flex-row overflow-x-auto  flex-1 gap-5 lg:gap-[45px] '>
        {investments.map(item => (
          <InvestContainer item={item} key={item.id} />
        ))}
      </div>
    </section>
  )
}

export default InvestmentSection
