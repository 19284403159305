import React from 'react'
import DataTable from '../../FarmPage/DataTable/DataTable'
import { columns } from '../OperationalCalendarColumn/OperationalCalendarColumn'
import { MobileColumns } from '../OperationalCalendarColumn/MobileOperationalCalendarColumn'
import EmptyDataContent from '../../../SharedComponents/EmptyDataContent/EmptyDataContent'
import EmptyFarmImage from '../../../../resources/farmemptyimage.png'
import useWindowSize from '../../UseWindowSize/UseWindowSize'

const OperationalCalendarDatatable = ({ data }) => {
  const { width } = useWindowSize()
  const columnsforcalendar = width > 1024 ? columns : MobileColumns
  return (
    <div className='w-full bg-[#F9F9F9] rounded-md h-full px-[2%] py-[1%]'>
      <DataTable
        data={data}
        columns={columnsforcalendar}
        pagination='true'
        calendarnav='true'
        emptydatacontent={
          <EmptyDataContent
            image={EmptyFarmImage}
            text='No calendar yet'
            desc='Once you request a calendar, you will see an overview here.'
          />
    }
      />
    </div>
  )
}

export default OperationalCalendarDatatable
