import React, { useState } from 'react'
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
  getSortedRowModel
} from '@tanstack/react-table'
import Pagination from '../../../SharedComponents/Pagination/Pagination'
import FarmFilter from '../../../EnterpriseApp/FarmPage/FarmFilter/FarmFilter'
import { useNavigate } from 'react-router-dom'
import StockFilter from '../../../StockPage/StockFilter/StockFilter'
import StaffFilter from '../../StaffPage/StaffFilter/StaffFilter'
const DataTable = ({
  data,
  columns,
  farmfilter,
  pagination = false,
  nav,
  stockfilter,
  stafffilter,
  emptydatacontent,
  calendarnav
}) => {
  const [sorting, setSorting] = useState([])
  const navigate = useNavigate()
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: { sorting }
  })

  const handleRowClick = row => {
    const stockData = {
      farmName: row.original.farm_name,
      createdBy: row.original.created_by,
      createdAt: row.original.created_at
    }

    nav &&
      navigate(`/stocks/${row.original.stock_record_id}`, {
        state: { stockData }
      })

    if (calendarnav && row.original.status !== 'pending') {
      navigate(`/operational-calendar/${row.original.id}`)
    }
  }

  return (
    <>
      {farmfilter && <FarmFilter data={data} table={table} />}
      {stockfilter && <StockFilter data={data} table={table} />}
      {stafffilter && <StaffFilter data={data} table={table} />}
      <div className='w-full text-sm  mt-[2%]'>
        {/* head */}
        {data.length === 0
          ? (
            <div className='w-full h-[400px] '>{emptydatacontent}</div>
            )
          : (
            <table className=' w-full divide-y-2 divide-gray-200 bg-[#F9F9F9] text-sm'>
              <thead className='ts'>
                {table.getHeaderGroups().map(headerGroup => (
                  <tr
                    key={headerGroup.id}
                    className='border-b border-[#DDDDDD] h-[60px] ts'
                  >
                    {headerGroup.headers.map(header => (
                      <th
                        className='text-[#444444] text-sm lg:text-base whitespace-nowrap px-4 py-2 font-medium text-left'
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {header.isPlaceholder
                          ? null
                          : (
                            <div>
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </div>
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map(row => (
                  <tr
                    key={row.id}
                    onClick={() => handleRowClick(row)}
                    className='border-b border-[#DDDDDD] h-[60px] cursor-pointer'
                  >
                    {row.getVisibleCells().map(cell => (
                      <td key={cell.id} className='px-4 py-2 text-sm'>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            )}

        {pagination && data.length > 0 && (
          <Pagination
            next={() => {
              table.nextPage()
            }}
            previous={() => {
              table.previousPage()
            }}
            nextpagedisabled={!table.getCanNextPage()}
            previouspagedisabled={!table.getCanPreviousPage()}
            totalPages={table.getPageCount()}
            current={table.getState().pagination.pageIndex + 1}
          />
        )}
      </div>
    </>
  )
}
export default DataTable
