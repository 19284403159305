import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import useBackendService from '../../../../config/services/backend-service'

const BasicPlanEmailVerifcation = () => {
  const [email, setEmail] = useState('')
  const [timer, setTimer] = useState(59)

  const { mutate: resendVerification, isLoading } = useBackendService(
    '/resend_otp',
    'post',
    {
      onSuccess: data => {
        setTimer(59)
        toast.success('Verification link has been resent successfully!')
      },
      onError: err => {
        toast.error('Failed to resend verification link. Please try again.')
      }
    }
  )

  useEffect(() => {
    let interval = null
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(prev => prev - 1)
      }, 1000)
    } else if (timer === 0) {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [timer])

  const handleResend = e => {
    e.preventDefault()
    resendVerification({ email })
  }

  return (
    <div className='content-cover'>
      <div className='w-full  space-y-1'>
        <h1 className='header-text'>Verify your email address</h1>
        <p className='text-black w-[90%] mt-2 '>
          Click the verification link that was sent to the email address below
          to confirm your details.
        </p>
      </div>

      <div className='mt-[8%] w-full'>
        <div className='email-wrapper'>
          <input
            type='email'
            className='email-input ts'
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <button className='change-btn'>Change</button>
        </div>
        <p className='text-[#4D5761] text-sm mt-2'>
          Didn’t get a verification link? Click resend
          {timer > 0 ? ' in' : ''}
          <span className='text-darkgreen font-bold'>
            {timer > 0 ? `(${timer}s)` : ''}
          </span>
        </p>

        <button
          onClick={handleResend}
          className={`proceed-btn mt-10 ts ${
            isLoading || !email.trim() || timer > 0
              ? 'bg-[#dddddd] text-black'
              : 'proceed-btn'
          }`}
          disabled={isLoading || !email.trim() || timer > 0}
        >
          {isLoading ? 'Resending...' : 'Resend'}
        </button>
      </div>

      <p className='login-text'>
        Have an account?{' '}
        <a href='/login' className='login-link'>
          Log in
        </a>
      </p>
    </div>
  )
}

export default BasicPlanEmailVerifcation
