import { capitalizeWords } from '../../../../../config/services/capitalize'

export const MobileColumns = [
  {
    accessorKey: 'farm_name',
    header: 'Farm',
    cell: context => {
      const farmName = context.getValue()
      const words = farmName.split(' ')

      // Check if the farm name exceeds a certain number of words, e.g., 3
      const formattedName =
        words.length > 2 ? `${words.slice(0, 2).join(' ')}...` : farmName

      return <span>{capitalizeWords(formattedName)}</span>
    }
  },
  {
    accessorKey: 'product',
    header: 'Product',
    cell: context => (
      <span className='ts'>{capitalizeWords(context.getValue())}</span>
    )
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: context => {
      const value = context.getValue()
      return (
        <span
          style={{
            color:
              value === 'Completed'
                ? 'green'
                : value === 'Pending'
                  ? 'orange'
                  : 'black'
          }}
        >
          {capitalizeWords(value)}
        </span>
      )
    }
  }
]
