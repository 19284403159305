import { Loader2, Check } from 'lucide-react'
import useBackendService from '../../../../../config/services/backend-service'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import Modal from '../../../SharedComponents/Modal/Modal'

const EnterPrisePlanPayment = ({ formData }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid }
  } = useForm()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)
  const [planType, setPlanType] = useState('monthly')
  const paystackPublicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY
  const params = new URLSearchParams(window.location.search)
  const [isModalOpen, setModalOpen] = useState(false)
  const [modal, setModal] = useState({
    type: '',
    title: '',
    desc: '',
    link: '',
    visible: false
  })

  const { mutate: createAdminUser } = useBackendService(
    '/auth/create_admin',
    'post',
    {
      onSuccess: data => {
        toast.success(
          'Admin user succesfully created. \n A verification link has been sent to you to confirm your email'
        )
        setIsLoading(false)
        navigate('/')
      },
      onError: error => {
        toast.error('Failed to create Admin')
      }
    }
  )

  const { mutate } = useBackendService('/ipn/paystack/save_recurring', 'post', {
    onSuccess: data => {
      const token = params.get('token')
      createAdminUser({
        ...formData,
        isAdmin: true,
        plan: 'Enterprise',
        token,
        role: 'administrator'
      })
    },
    onError: error => {}
  })

  const onSubmit = async data => {
    setIsLoading(true)
    const { cardnumber, expirydate, cvv } = data

    const amount = planType === 'monthly' ? 100000 * 100 : 1200000 * 100

    try {
      const paystack = window.PaystackPop.setup({
        key: paystackPublicKey,
        email: formData.email,
        amount,
        card: {
          number: cardnumber,
          cvv,
          expiry_month: expirydate.split('/')[0],
          expiry_year: expirydate.split('/')[1]
        },
        channels: ['card'],
        callback: response => {
          toast.success(`Payment successful! Reference: ${response.reference}`)
          setIsCompleted(true)
          mutate({
            reference: response.reference,
            plan: 'Enterprise',
            type: planType
          })
        },
        onClose: () => {
          toast.error('Payment window was closed.')
        }
      })

      paystack.openIframe()
    } catch (error) {
      setModal({
        type: 'error',
        title: 'Unable to initiate Payment process',
        desc: 'You are unable to initiate Payment process at the moment.',
        visible: true
      })
      setModalOpen(true)
    }
  }

  return (
    <>
      {!isLoading && !isCompleted
        ? (
          <div className='w-full h-[70vh] lg:h-full'>
            <h1 className='text-darkGreen font-semibold lg:text-lg'>
              Enterprise Plan Payment
            </h1>
            <p className='ts text-[#666666]'>
              To finish setting up your account kindly input your card details to
              process the transaction.
            </p>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className='mt-[5%] space-y-5 w-full'
            >
              <div className='flex gap-2'>
                <div className='w-full flex gap-2 justify-start items-center'>
                  <input
                    type='checkbox'
                    className='jj'
                    value='monthly'
                    checked={planType === 'monthly'}
                    onChange={() => setPlanType('monthly')}
                  />
                  <h1 className='text-[#666666] ts'>Monthly: ₦100,000 </h1>
                </div>

                <div className='w-full flex gap-2 justify-start items-center'>
                  <input
                    type='checkbox'
                    className='jj'
                    value='yearly'
                    checked={planType === 'yearly'}
                    onChange={() => setPlanType('yearly')}
                  />
                  <h1 className='text-[#666666] ts'> Yearly: ₦1,200,000 </h1>
                </div>
              </div>

              <div className='grid w-full items-center gap-1.5'>
                <label htmlFor='email'>Card number</label>
                <input
                  type='number'
                  name='cardnumber'
                  placeholder='0000 0000 0000 0000'
                  {...register('cardnumber', {
                    required: 'Card number is required',
                    minLength: {
                      value: 16,
                      message: 'Card number must be at least 16 digits'
                    },
                    pattern: {
                      value: /^[0-9]+$/,
                      message: 'Card number must be a number'
                    }
                  })}
                  className='email-input'
                />

                {errors.cardnumber && <p>{errors.cardnumber.message}</p>}
              </div>
              <div className='w-full center gap-4'>
                <div className='grid w-[50%] items-center gap-1.5'>
                  <label htmlFor='email'>Expiry Date</label>
                  <input
                    type='text'
                    name='expirydate'
                    placeholder='MM/YY'
                    {...register('expirydate', {
                      required: 'Expiry date is required',
                      pattern: {
                        value: /^(0[1-9]|1[0-2])\/([0-9]{2})$/,
                        message: 'Invalid expiry date format (MM/YY)'
                      }
                    })}
                    className='email-input'
                  />
                </div>
                <div className='grid w-[50%] items-center gap-1.5'>
                  <label htmlFor='email'>CVV</label>
                  <input
                    type='text'
                    name='cvv'
                    placeholder='123'
                    maxLength={3}
                    {...register('cvv', {
                      required: 'CVV is required',
                      pattern: {
                        value: /^[0-9]{3}$/,
                        message: 'Invalid CVV format (3 digits)'
                      }
                    })}
                    className='email-input'
                  />
                </div>
              </div>
              <div className='w-full flex gap-2 justify-start items-center'>
                <input type='checkbox' className='jj' />
                <h1 className='text-[#666666] ts'>
                  Save card details for recurring payment
                </h1>
              </div>
              <div className='w-full pt-[5%]'>
                <button
                  type='submit'
                  className={`ts ${
                  isDirty && isValid
                    ? 'activebutton'
                    : 'h-[50px] w-full rounded-[8px] bg-darkash text-black'
                }`}
                  disabled={!(isDirty && isValid)}
                >
                  Make Payment
                </button>
              </div>
            </form>
          </div>
          )
        : isLoading
          ? (
            <div className='w-full h-[70vh] lg:h-screen flex items-center justify-center flex-col gap-4 text-center '>
              <Loader2 size={40} className='animate-spin' />
              <h1 className='font-semibold text-xl'>Payment Processing</h1>
              <p className='text-[#666666] ts'>
                Please be patient and bear with us while we process your plan
                payment.
              </p>
              <button className='w-full lg:w-[80%] mx-auto h-[50px] bg-darkGreen text-white ts rounded-[8px] center'>
                Continue
              </button>
            </div>
            )
          : (
            <div className='w-full h-[70vh] lg:h-screen flex items-center justify-center flex-col gap-4 text-center '>
              <div className='border-2 rounded-full p-2 border-solid border-darkgreen'>
                <Check size={40} className='text-darkGreen' />
              </div>
              <h1 className='font-semibold text-xl'>
                Well-done, Payment Successful
              </h1>
              <p className='text-[#666666] ts'>
                Your payment of of{' '}
                {planType === 'monthly' ? '₦100,000' : '₦1,200,000'} for an
                Enterprise account was successful. Kindly continue to setup your
                account.
              </p>
              <button className='w-full lg:w-[80%] mx-auto h-[50px] bg-darkGreen text-white ts rounded-[8px] center'>
                Continue
              </button>
            </div>
            )}
      {modal.visible && (
        <Modal
          type={modal.type}
          title={modal.title}
          desc={modal.desc}
          link={modal.link}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </>
  )
}

export default EnterPrisePlanPayment
