import { MoreHorizontal } from 'lucide-react'
import { capitalizeWords } from '../../../../../config/services/capitalize'

export const columns = [
  {
    accessorKey: 'name',
    header: 'Name',
    cell: context => (
      <span className='ts'>{capitalizeWords(context.getValue())}</span>
    )
  },
  {
    accessorKey: 'type',
    header: 'Type',
    cell: context => (
      <span className='ts'>{capitalizeWords(context.getValue())}</span>
    )
  },
  {
    accessorKey: 'unit_type',
    header: 'Unit',
    cell: context => (
      <span className='ts'>{capitalizeWords(context.getValue())}</span>
    )
  },
  {
    accessorKey: 'manufacturer',
    header: 'Manufacturer',
    cell: context => (
      <span className='ts'>{capitalizeWords(context.getValue())}</span>
    )
  },
  {
    accessorKey: 'active',
    header: 'Status',
    cell: context => {
      const value = context.getValue()
      return (
        <span style={{ color: value === false ? 'red' : 'green' }}>
          {value === true ? 'Active' : 'Inactive'}
        </span>
      )
    }
  },
  {
    accessorKey: 'unit_value',
    header: 'Stock'
  },

  {
    accessorKey: 'action',
    header: 'Action',

    enableHiding: false,
    cell: () => {
      return <MoreHorizontal className='h-4 w-4' />
    }
  }
]
