import React from 'react'
import { useLocation } from 'react-router-dom'

const MobileSidebar = ({ name, icon, link, orange }) => {
  const location = useLocation()
  const path = location.pathname
  const isActive =
    (path === '/' && link === '/dashboard') || path.startsWith(link)

  return (
    <div className='w-full '>
      <a
        href={link}
        className={`w-full flex flex-row items-center justify-center ${
          isActive ? ' bg-darkGreen' : ''
        }`}
      >
        <div className='w-full px-[30px] flex gap-3 capitalize text-gray-600 justify-start  text-sm  h-[45px]  items-center'>
          {isActive && orange
            ? (
              <img src={orange} alt='icons' className='w-[20px] h-[20px]' />
              )
            : icon
              ? (
                <img src={icon} alt='icons' className='w-[20px] h-[20px]' />
                )
              : null}
          <h1
            className={` font-medium ${
              isActive ? 'text-white font-semibold' : 'text-black'
            }`}
          >
            {name}
          </h1>
        </div>
      </a>
    </div>
  )
}

export default MobileSidebar
