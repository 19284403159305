import React from 'react'
import TextInput from '../../../../SharedComponents/TextInput/TextInput'
import { useForm } from 'react-hook-form'
import TextArea from '../../../../SharedComponents/TextAreaInput/TextAreaInput'

const NoQuestionComponent = ({ request, onFormSubmit }) => {
  const {
    register,
    // handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'onChange' })
  const onSubmit = async data => {
    if (onFormSubmit) {
      onFormSubmit()
    }
  }

  return (
    <form
      // onSubmit={handleSubmit(onSubmit)}
      className='w-full lg:w-[90%] space-y-5'
    >
      <div className='flex flex-col lg:flex-row gap-5 lg:gap-10 items-center'>
        <div className='w-full lg:w-[418px]'>
          <TextInput
            name='product'
            register={register}
            errors={errors}
            text='Product'
            dvalue={request.product}
            placeholder='Input here'
          />
        </div>
        <div className='w-full lg:w-[418px]'>
          <TextInput
            name='questionvalue'
            register={register}
            errors={errors}
            dvalue={request.value_chain}
            text='Have you gone into this value chain before?'
            placeholder='Input here'
          />
        </div>
      </div>
      <div className='flex flex-col lg:flex-row gap-5 lg:gap-10 items-center'>
        <div className='w-full lg:w-[418px]'>
          <TextInput
            name='projectstatus'
            register={register}
            errors={errors}
            text='Project status'
            dvalue={request.project_status || ''}
            placeholder='Input here'
          />
        </div>
      </div>
      <div className='w-full'>
        <TextArea
          name='challengesencountered'
          register={register}
          errors={errors}
          text='Challenges encountered?'
          value={request.challenges || ''}
        />
      </div>
      <div className='w-full'>
        <TextArea
          name='areasneed'
          register={register}
          errors={errors}
          text='Areas you need help?'
          value={request.need_help || ''}
        />
      </div>
      <div className='w-full'>
        <TextArea
          name='otherdetails'
          register={register}
          errors={errors}
          text='Any other details?'
          value={request.other_details || ''}
        />
      </div>
      <div className='w-full lg:w-[40%]'>
        <button
          type='button'
          onClick={onSubmit}
          className='proceed-btn bg-darkGreen text-white'
        >
          Generate output
        </button>
      </div>
    </form>
  )
}

export default NoQuestionComponent
