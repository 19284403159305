import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import Map, { Marker } from 'react-map-gl'
import { Search, X } from 'lucide-react'
import Filter from '../Filter/Filter'
import PayWall from '../PayWall/PayWall'
import { useAuth } from '../../../../config/contexts/authProvider'
import useBackendService from '../../../../config/services/backend-service'

const getRandomColor = () => {
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

function RegularMap ({ unlock = false }) {
  const { user } = useAuth()
  const [data, setData] = useState([])
  const [coloredData, setColoredData] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const colorMapRef = useRef({})

  const { mutate: product } = useBackendService('/product_catalogues', 'get', {
    onSuccess: data => {
      setData(data.data)
    },
    onError: error => {
      console.log(error)
    }
  })

  useEffect(() => {
    product()
  }, [product])

  const assignColorsToProducts = useCallback(products => {
    return products.map(product => {
      if (!colorMapRef.current[product.id]) {
        colorMapRef.current[product.id] = getRandomColor()
      }
      return {
        ...product,
        bg: colorMapRef.current[product.id]
      }
    })
  }, [])

  useEffect(() => {
    if (data.length > 0) {
      const productsWithColors = assignColorsToProducts(data)
      setColoredData(productsWithColors)
    }
  }, [data, assignColorsToProducts])

  const [viewPort, setViewPort] = useState({
    latitude: 9,
    longitude: 8,
    zoom: 6
  })

  const filteredData = useMemo(() => {
    if (!searchTerm) return coloredData
    return coloredData.filter(item =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
  }, [coloredData, searchTerm]) // Use coloredData

  const locations = filteredData.map(item => ({
    id: item,
    label: item.state
  }))

  const uniqueLocations = useMemo(() => {
    return [...new Set(locations.map(loc => loc.label))]
  }, [locations])

  const products = filteredData.map(item => ({
    id: item,
    label: item.name
  }))

  const uniqueProducts = useMemo(() => {
    return [...new Set(products.map(prod => prod.label))]
  }, [products])

  return (
    <div className=''>
      {unlock || user.plan === 'Pro'
        ? (
          <div className='flex flex-col-reverse lg:flex-col gap-[30px] lg:px-5 py-5 lg:py-10 w-full h-full'>
            <div className='flex flex-col-reverse gap-[15px] px-3 lg:px-0 lg:flex-col lg:gap-30px '>
              <div className=' flex flex-row justify-between  '>
                <div className='flex flex-[1] items-center border rounded-lg gap-1 px-3'>
                  <Search size={20} color='#DDDDDD' />
                  <input
                    className=' bg-transparent appearance-none border-none p-2 placeholder:text-[12px] placeholder:lg:text-[16px] text-[12px] lg:text-[16px]  text-gray-900 placeholder-[#888888] focus:outline-none focus:ring-0'
                    type='search'
                    placeholder='Search Crops, Livestock, Location'
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                  />
                </div>
                <Filter location={uniqueLocations} products={uniqueProducts} />
              </div>
              <div className=' flex flex-col  gap-4 capitalize '>
                <p className=' text-black font-[700] text-[14px] lg:text-[16px] '>
                  Currently displayed
                </p>
                <div className='gap-5 grid grid-cols-4 lg:grid-cols-7'>
                  {filteredData.map((product, index) => (
                    <div className=' flex gap-1 items-center ' key={index}>
                      <div
                        className=' w-[10px] h-[10px] rounded-full '
                        style={{ backgroundColor: product.bg }}
                      />
                      <p className=' text-black font-[500] text-[12px] lg:text-[16px] '>
                        {product.name}
                      </p>
                      <X size={14} className=' text-black font-[500]' />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className=' w-full h-[60%] lg:h-[600px] '>
              <Map
                style={{ width: '100%', height: '100%' }}
                initialViewState={viewPort}
                onMove={viewPort => {
                  setViewPort(viewPort)
                }}
                mapStyle='mapbox://styles/mapbox/streets-v9'
                mapboxAccessToken='pk.eyJ1IjoidGlkYWwtbGluayIsImEiOiJjbHo2cmJkd2UwMWxtMnNzNWdqcjd6MGdlIn0.dUx1-wpG4c5lc5F2eFz5zg'
              >
                {filteredData.map((product, index) => (
                  <Marker
                    key={index}
                    longitude={product.longitude}
                    latitude={product.latitude}
                  >
                    <div
                      className=' w-[14px] h-[14px] rounded-full'
                      style={{ backgroundColor: product.bg }}
                    />
                  </Marker>
                ))}
              </Map>
            </div>
          </div>
          )
        : (
          <RegularView />
          )}
    </div>
  )
}

const RegularView = () => (
  <div className=' w-full h-[90vh] items-center justify-center'>
    <PayWall text='Upgrade to the Pro plan to access detailed packaging options, market trends and current market price' />
  </div>
)

export default RegularMap
