import React, { useState } from 'react'
import '../SalesDescription.css'
import { ChevronDown, ChevronUp } from 'lucide-react'

const SalesDropdown = ({ salesproductsdetails }) => {
  const [checkInDropdownOpen, setCheckInDropdownOpen] = useState(false)
  const [salesDropdownOpen, setSalesDropdownOpen] = useState(false)
  const [packagingDropdownOpen, setPackagingDropdownOpen] = useState(false)
  const [logisticDropdownOpen, setLogisticDropdownOpen] = useState(false)

  const toggleDropdown = setDropdownState => {
    setDropdownState(prevState => !prevState)
  }

  return (
    <div className='accordion-wrapper'>
      {/* left */}
      <div className='w-full lg:w-[50%] space-y-3'>
        <div className='w-full border border-gray-100 rounded-md p-[2%]'>
          <div
            className='w-full flex justify-between items-center p-2'
            onClick={() => toggleDropdown(setCheckInDropdownOpen)}
          >
            <div className='flex flex-col justify-center items-start ts space-y-1'>
              <h1 className='font-semibold'>Storage solutions</h1>
            </div>
            {checkInDropdownOpen
              ? (
                <ChevronUp className='iconborder' />
                )
              : (
                <ChevronDown className='iconborder' />
                )}
          </div>
          {checkInDropdownOpen && <hr className='border border-gray-100' />}
          <div
            className={`dropdown-content ${
              checkInDropdownOpen ? 'open' : ''
            } bg-white w-full ts p-2`}
          >
            {salesproductsdetails.storage_solutions}
          </div>
        </div>

        <div className='w-full border border-gray-100 rounded-md p-[2%]'>
          <div
            className='w-full flex justify-between items-center p-2'
            onClick={() => toggleDropdown(setSalesDropdownOpen)}
          >
            <div className='flex flex-col justify-center items-start ts space-y-1'>
              <h1 className='font-semibold'>Sales channel</h1>
            </div>
            {salesDropdownOpen
              ? (
                <ChevronUp className='iconborder' />
                )
              : (
                <ChevronDown className='iconborder' />
                )}
          </div>
          {salesDropdownOpen && <hr className='border border-gray-100' />}
          <div
            className={`dropdown-content ${
              salesDropdownOpen ? 'open' : ''
            } bg-white w-full ts p-2`}
          >
            {salesproductsdetails.sales_channels}
          </div>
        </div>
      </div>
      {/* right */}
      <div className='w-full lg:w-[50%] space-y-2'>
        <div className='w-full border border-gray-100 rounded-md p-[2%]'>
          <div
            className='w-full flex justify-between items-center p-2'
            onClick={() => toggleDropdown(setPackagingDropdownOpen)}
          >
            <div className='flex flex-col justify-center items-start ts space-y-1'>
              <h1 className='font-semibold'>Packaging methods</h1>
            </div>
            {packagingDropdownOpen
              ? (
                <ChevronUp className='iconborder' />
                )
              : (
                <ChevronDown className='iconborder' />
                )}
          </div>
          {packagingDropdownOpen && <hr className='border border-gray-100' />}
          <div
            className={`dropdown-content ${
              packagingDropdownOpen ? 'open' : ''
            } bg-white w-full ts p-2`}
          >
            {salesproductsdetails.packaging_methods}
          </div>
        </div>

        <div className='w-full border border-gray-100 rounded-md p-[2%]'>
          <div
            className='w-full flex justify-between items-center p-2'
            onClick={() => toggleDropdown(setLogisticDropdownOpen)}
          >
            <div className='flex flex-col justify-center items-start ts space-y-1'>
              <h1 className='font-semibold'>Logistics solutions</h1>
            </div>
            {logisticDropdownOpen
              ? (
                <ChevronUp className='iconborder' />
                )
              : (
                <ChevronDown className='iconborder' />
                )}
          </div>
          {logisticDropdownOpen && <hr className='border border-gray-100' />}
          <div
            className={`dropdown-content ${
              logisticDropdownOpen ? 'open' : ''
            } bg-white w-full ts p-2`}
          >
            {salesproductsdetails.logistic_providers}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SalesDropdown
