import CreatePassword from '../../../SharedComponents/CreatePaasword/CreatePassword'

import { useForm } from 'react-hook-form'

const EnterpriseAdminCreatePassword = ({ setFormData, next }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty, isValid },
    trigger
  } = useForm()

  const password = watch('password')
  const confirmPassword = watch('confirmpassword')

  const handlePasswordChange = async e => {
    await trigger('password')
  }

  // function to submitting the form data
  const onSubmit = async data => {
    const isValid = await trigger(['password', 'confirmpassword', 'email'])
    if (!isValid) return
    setFormData(prevData => ({ ...prevData, ...data }))
    next()
  }

  return (
    <>
      <CreatePassword
        password={password}
        confirmPassword={confirmPassword}
        watch={watch}
        handlePasswordChange={handlePasswordChange}
        handleSubmit={handleSubmit}
        errors={errors}
        isDirty={isDirty}
        isValid={isValid}
        register={register}
        onSubmit={onSubmit}
        title='Create Password'
        desc='Kindly fill in your details to setup your account'
      />
    </>
  )
}

export default EnterpriseAdminCreatePassword
