import React from 'react'
import { useForm } from 'react-hook-form'
import { useAuth } from '../../../../config/contexts/authProvider'

const EnterpriseAccountSignUp = ({ next }) => {
  const { setparentFarmField } = useAuth()

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid }
  } = useForm({ mode: 'onChange' })
  // function to submitting the form data
  const onSubmit = async data => {
    next()
    setparentFarmField('email', data.email)
    setparentFarmField('phoneNumber', data.number)
  }
  return (
    <div className='content-cover'>
      <div className='w-full  space-y-1'>
        <h1 className='header-text'>Enterprise Account</h1>
        <p className='text-black '>
          Kindly fill in your details to setup your account
        </p>
      </div>

      <form className='form-wrapper' onSubmit={handleSubmit(onSubmit)}>
        <div className='grid w-full  items-center gap-1.5 ts'>
          <label htmlFor='email'>Email</label>
          <input
            type='email'
            name='email'
            {...register('email', { required: 'Email is required' })}
            placeholder='Email'
            className='input-style email-input ts'
          />
          {errors.email && <p className='error-text'>{errors.email.message}</p>}
        </div>

        <div className='grid w-full  items-center gap-1.5 ts'>
          <label htmlFor='text'>Phone number</label>
          <input
            type='number'
            name='number'
            {...register('number', { required: 'Phone number is required' })}
            placeholder='+234'
            className='input-style email-input ts'
          />
          {errors.number && (
            <p className='error-text'>{errors.number.message}</p>
          )}
        </div>
        <button
          type='submit'
          className={`ts ${isDirty && isValid ? 'activebutton' : 'notactive'}`}
          disabled={!(isDirty && isValid)}
        >
          Proceed
        </button>
      </form>

      <p className='login-text'>
        Have an account?{' '}
        <a href='/login' className='login-link'>
          Log in
        </a>
      </p>
    </div>
  )
}

export default EnterpriseAccountSignUp
