import { MoreHorizontal } from 'lucide-react'
import { capitalizeWords } from '../../../../../config/services/capitalize'

export const MobileColumns = [
  {
    accessorKey: 'name',
    header: 'Name',
    cell: context => {
      const value = context.getValue()
      return <div className='w-[100px]'>{capitalizeWords(value)}</div>
    }
  },

  {
    accessorKey: 'address',
    header: 'Address',
    cell: context => {
      const value = context.getValue()
      return <div className='w-[120px]'>{capitalizeWords(value)}</div>
    }
  },

  {
    accessorKey: 'action',
    header: 'Action',
    enableHiding: false,
    cell: () => {
      return <MoreHorizontal className='h-4 w-4' />
    }
  }
]
