import React from 'react'
import { useForm } from 'react-hook-form'
import TextInput from '../../../../SharedComponents/TextInput/TextInput'

const YesQuestionComponent = ({ request, onFormSubmit }) => {
  const {
    register,
    // handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'onChange' })
  const onSubmit = async data => {
    console.log(data)
    if (onFormSubmit) {
      onFormSubmit()
    }
  }
  return (
    <form
      // onSubmit={handleSubmit(onSubmit)}
      className='w-full lg:w-[90%] space-y-5'
    >
      <div className='flex flex-col lg:flex-row gap-5 lg:gap-10 items-center'>
        <div className='w-full lg:w-[418px]'>
          <TextInput
            name='product'
            register={register}
            errors={errors}
            text='Product'
            dvalue={request.product}
            placeholder='Input here'
          />
        </div>
        <div className='w-full lg:w-[418px]'>
          <TextInput
            name='questionvalue'
            register={register}
            errors={errors}
            dvalue={request.value_chain}
            text='Have you gone into this value chain before?'
            placeholder='Input here'
          />
        </div>
      </div>
      <div className='flex flex-col lg:flex-row gap-5 lg:gap-10 items-center'>
        <div className='w-full lg:w-[418px]'>
          <TextInput
            name='location'
            register={register}
            errors={errors}
            text='Location'
            dvalue={request.location || ''}
            placeholder='Input here'
          />
        </div>
        <div className='w-full lg:w-[418px]'>
          <TextInput
            name='capital'
            register={register}
            errors={errors}
            dvalue={request.capital || ''}
            text='Capital'
            placeholder='Input here'
          />
        </div>
      </div>
      <div className='flex flex-col lg:flex-row gap-5 lg:gap-10 items-center'>
        <div className='w-full lg:w-[418px]'>
          <TextInput
            name='landsize'
            register={register}
            errors={errors}
            text='Land size'
            dvalue={request.land_size || ''}
            placeholder='Input here'
          />
        </div>
        <div className='w-full lg:w-[418px]'>
          <TextInput
            name='startingdate'
            register={register}
            errors={errors}
            dvalue={request.starting_date || ''}
            text='Starting date'
            placeholder='Input here'
          />
        </div>
      </div>
      <div className='w-full lg:w-[40%] pt-[10%]'>
        <button
          type='button'
          onClick={onSubmit}
          className='proceed-btn bg-darkGreen text-white'
        >
          Generate output
        </button>
      </div>
    </form>
  )
}

export default YesQuestionComponent
