import { Plus, X } from 'lucide-react'
import React, { useEffect, useState } from 'react'

const DynamicTextArea = ({
  errors,
  text,
  label,
  existingTextAreas,
  register,
  name,
  nextbtn,
  isMarketPrice
}) => {
  const [textAreas, setTextAreas] = useState([{ value: '', date: '' }])

  useEffect(() => {
    if (existingTextAreas && existingTextAreas.length > 0) {
      setTextAreas(existingTextAreas)
    }
  }, [existingTextAreas])

  const handleTextAreaChange = (index, fieldName, fieldValue) => {
    const updatedTextAreas = [...textAreas]
    updatedTextAreas[index][fieldName] = fieldValue
    setTextAreas(updatedTextAreas)
  }

  const addTextArea = () => {
    setTextAreas([...textAreas, { value: '', date: '' }])
  }

  const removeTextArea = index => {
    const updatedTextAreas = [...textAreas]
    updatedTextAreas.splice(index, 1)
    setTextAreas(updatedTextAreas)
  }

  return (
    <div className='grid w-full items-center gap-1.5'>
      {textAreas.map((area, index) => (
        <div key={index} className='w-full'>
          {isMarketPrice
            ? (
              <div className='flex gap-4'>
                <div className='flex flex-col w-1/2'>
                  <label htmlFor={`date-${index}`}>Date</label>
                  <input
                    type='date'
                    id={`date-${index}`}
                    {...register(`${name}.${index}.date`)}
                    value={area.date}
                    onChange={e =>
                      handleTextAreaChange(index, 'date', e.target.value)}
                    className='textarea rounded-lg w-full bg-[#F3F5F6] h-auto lg:h-[50px]'
                  />
                  {errors?.[name]?.[index]?.date && (
                    <p className='error-text'>
                      {errors[name][index].date.message}
                    </p>
                  )}
                </div>
                <div className='flex flex-col w-1/2'>
                  <label htmlFor={`price-${index}`}>Market Price</label>
                  <input
                    type='number'
                    id={`price-${index}`}
                    {...register(`${name}.${index}.value`)}
                    value={area.value}
                    onChange={e =>
                      handleTextAreaChange(index, 'value', e.target.value)}
                    className='textarea rounded-lg w-full bg-[#F3F5F6] h-auto lg:h-[50px]'
                  />
                  {errors?.[name]?.[index]?.value && (
                    <p className='error-text'>
                      {errors[name][index].value.message}
                    </p>
                  )}
                </div>
              </div>
              )
            : (
              <div>
                {text && (
                  <label htmlFor={`textarea-${index}`}>{label && label}</label>
                )}
                <textarea
                  {...register(`${name}.${index}.value`)}
                  placeholder='Write a short description'
                  value={area.value}
                  onChange={e =>
                    handleTextAreaChange(index, 'value', e.target.value)}
                  className='textarea rounded-lg w-full bg-[#F3F5F6] h-auto lg:h-[160px]'
                />
                {errors?.[name]?.[index]?.value && (
                  <p className='error-text'>
                    {errors[name][index].value.message}
                  </p>
                )}
              </div>
              )}
          {index > 0 && (
            <div className='flex justify-end items-center gap-2.5 mt-1'>
              <X className='text-red-300' size={14} />
              <div
                className='text-red-300 text-[12px] cursor-pointer'
                onClick={() => removeTextArea(index)}
              >
                Remove
              </div>
            </div>
          )}
        </div>
      ))}
      <div className='items-center flex gap-1'>
        <Plus className='text-darkGreen' />
        <div
          onClick={addTextArea}
          className='text-[12px] text-darkGreen lg:text-[14px] cursor-pointer'
        >
          {nextbtn}
        </div>
      </div>
    </div>
  )
}

export default DynamicTextArea
