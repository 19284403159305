import { capitalizeWords } from '../../../../../config/services/capitalize'

export const columns = [
  {
    accessorKey: 'farm_name',
    header: 'Farm',
    cell: context => (
      <span className='ts'>{capitalizeWords(context.getValue())}</span>
    )
  },
  {
    accessorKey: 'product',
    header: 'Product',
    cell: context => (
      <span className='ts'>{capitalizeWords(context.getValue())}</span>
    )
  },
  {
    accessorKey: 'created_by_full_name',
    header: 'Requested',
    cell: context => (
      <span className='ts'>{capitalizeWords(context.getValue())}</span>
    )
  },
  {
    accessorKey: 'location',
    header: 'Location',
    cell: context => <span className='ts'>{context.getValue()}</span>
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: context => {
      const value = context.getValue()
      return (
        <span
          className='capitalize'
          style={{
            color:
              value === 'pending'
                ? 'red'
                : value === 'completed'
                  ? 'green'
                  : '#F29339'
          }}
        >
          {capitalizeWords(value)}
        </span>
      )
    }
  }
]
