import React from 'react'

const TextArea = ({
  name,
  register,
  errors,
  text,
  subtext,
  value,
  placeholder,
  disabled
}) => {
  return (
    <div className='grid w-full items-center gap-1.5 '>
      <label htmlFor='comments' className='ts'>{text}</label>

      {subtext && (
        <p className='text-[#888888] text-[16px] font-[400]'>{subtext}</p>
      )}

      <textarea
        name={name || text}
        {...register(`${name || text}`, {
          required: `${text} is required`
        })}
        placeholder={placeholder || 'Write a short description'}
        className='outline-none p-3 rounded-lg overflow-hidden bg-[#F3F5F6] h-[80px] lg:h-[160px] ts '
        defaultValue={value || ''}
        disabled={disabled}
      />

      {errors[text] && <p className='error-text'>{errors[text].message}</p>}
    </div>
  )
}

export default TextArea
