import { capitalizeWords } from '../../../../../config/services/capitalize'

export const columns = [
  {
    accessorKey: 'title',
    header: 'Title',
    cell: context => (
      <span className='ts'>{capitalizeWords(context.getValue())}</span>
    )
  },
  {
    accessorKey: 'type',
    header: 'Type',
    cell: context => (
      <span className='ts'>{capitalizeWords(context.getValue())}</span>
    )
  },
  {
    accessorKey: 'created_at',
    header: 'Date&Time',
    cell: context => {
      const value = context.getValue()
      const formattedDate = new Date(value).toLocaleDateString()
      const formattedTime = new Date(value).toLocaleTimeString()
      return (
        <span>
          {formattedDate}-{formattedTime}
        </span>
      )
    }
  },
  {
    accessorKey: 'assigned_to_email',
    header: 'AssignedTo',
    cell: context => (
      <span className='ts'>{capitalizeWords(context.getValue())}</span>
    )
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: context => {
      const value = context.getValue()
      return (
        <span
          style={{
            color:
              value === 'Rejected'
                ? 'red'
                : value === 'Acknowledged'
                  ? 'green'
                  : '#F29339'
          }}
        >
          {capitalizeWords(value)}
        </span>
      )
    }
  }
]
