import React from 'react'
import Footer from '../../components/Footer/Footer'
import ComprehensiveToolSection from './ComprehensiveToolSection/ComprehensiveToolSection'
import TgaimsWorkSection from './TgaimsWorkSection/TgaimsWorkSection'
import HeroSection from './HeroSection/HeroSection'
import PricingPlanSection from './PricingPlanSection/PricingPlanSection'
import FrequentlyAskQuestionSection from './FrequentlyAskQuestionSection/FrequentlyAskQuestionSection'
import NewsSubscriptionSection from './NewsSubscriptionSection/NewsSubscriptionSection'

const bgImg = require('../../assets/home-hero.png')

function Home () {
  return (
    <main>
      {/* HeroSection */}
      <HeroSection bgImg={bgImg} />
      {/* ComprehensiveToolSection */}
      <ComprehensiveToolSection />
      {/* TgaimsWorkSection */}
      <TgaimsWorkSection />
      {/* pricing section */}
      <PricingPlanSection />
      {/* Frequently ask question section */}
      <FrequentlyAskQuestionSection />
      {/* News Subscription Section */}
      <NewsSubscriptionSection />
      <Footer />
    </main>
  )
}

export default Home
