import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter as Router } from 'react-router-dom'
import { persistor, store } from './config/store/configureStore'
import AuthProvider from './config/contexts/authProvider'
import QueryProvider from './config/hooks/providers/query-provider'
import AutoLogout from './config/auto/logout'
const Root = () => (
  <div style={{ overflow: 'hidden', position: 'relative', width: '100%', height: '100%' }}>
    <App />
  </div>
)
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <Router>
          <AuthProvider>
            <QueryProvider>
              <AutoLogout />
              <Root>
                <App />
              </Root>
            </QueryProvider>
          </AuthProvider>
        </Router>
      </React.StrictMode>
    </PersistGate>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
