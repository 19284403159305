import React, { useEffect, useState } from 'react'
import BackButton from '../../../SharedComponents/BackButton/BackButton'
import Button from '../../../SharedComponents/Button/Button'
import DescriptionCalendar from './OperationalCalendarDescription/OperationalDescriptionCalendar'
import useBackendService from '../../../../../config/services/backend-service'
import { useAuth } from '../../../../../config/contexts/authProvider'
import { useParams } from 'react-router-dom'
import Modal from '../../../SharedComponents/Modal/Modal'

const formatDate = dateString => {
  const date = new Date(dateString)

  const options = { day: '2-digit', month: 'long', year: 'numeric' }
  return date.toLocaleDateString('en-GB', options)
}

const OperationalCalendarDescription = () => {
  const { user } = useAuth()
  const [farm, setFarm] = useState({})
  const [parentFarm, setParentFarm] = useState({})
  const [calendar, setCalendar] = useState({})
  const { id } = useParams()
  const [isModalOpen, setModalOpen] = useState(false)
  const [modal, setModal] = useState({
    type: '',
    title: '',
    desc: '',
    link: '',
    visible: false // Add a visible property for modal
  })

  const { mutate: fetchFarm } = useBackendService(
    `/farms/getone_id/${user.farmId}`,
    'get',
    {
      onSuccess: response => {
        setFarm(response.data)
        if (!response.data.is_parent_farm) {
          fetchParentFarm(response.data.parent_farm_id)
        }
      },
      onError: () => {
        setModal({
          type: 'error',
          title: 'Unable to Fetch details',
          desc: 'You are unable to fetch calendar details at the moment.',
          visible: true
        })
        setModalOpen(true)
      }
    }
  )
  const { mutate: fetchParentFarm } = useBackendService(
    id => `/farms/getone_id/${id}`,
    'get',
    {
      onSuccess: response => {
        setParentFarm(response.data)
      },
      onError: () => {
        setModal({
          type: 'error',
          title: 'Unable to Fetch details',
          desc: 'You are unable to fetch calendar details at the moment.',
          visible: true
        })
        setModalOpen(true)
      }
    }
  )
  const { mutate: FetchCalendar } = useBackendService(
    `/calendar/${id}`,
    'get',
    {
      onSuccess: response => {
        setCalendar(response.data)
      },
      onError: () => {
        setModal({
          type: 'error',
          title: 'Unable to Fetch details',
          desc: 'You are unable to fetch calendar details at the moment.',
          visible: true
        })
        setModalOpen(true)
      }
    }
  )

  useEffect(() => {
    fetchFarm()
    FetchCalendar()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='w-full h-full p-[2%]'>
      <div className='w-full flex justify-between items-center'>
        <BackButton />
        <div className='flex justify-end items-center gap-3 w-[55%] lg:w-[30%]'>
          <Button
            title='Contact Us for More'
            classname='bg-[#ADEE68] text-darkGreen w-[200px]'
          />
          <Button title='Print' classname='text-white bg-darkGreen w-[100px]' />
        </div>
      </div>
      <h1 className='font-semibold w-full'>Calendar</h1>
      <div className='w-full flex justify-between items-start mt-3 gap-2 lg:flex-row flex-col'>
        <div className='lg:w-[50%] h-[38vh] bg-darkGreen text-white capitalize p-8 ts rounded-md w-full center flex-col gap-5'>
          <div className='w-full flex justify-between items-center'>
            <h1>Parent farm:</h1>
            <h1>{farm.is_parent_farm ? farm.name : parentFarm.name}</h1>
          </div>
          <div className='w-full flex justify-between items-center'>
            <h1>Sub-farm:</h1>
            <h1>{farm.is_parent_farm ? '' : farm.name}</h1>
          </div>
          <div className='w-full flex justify-between items-center'>
            <h1>Product:</h1>
            <h1>{calendar.farmActivity?.product || ''}</h1>
          </div>
          <div className='w-full flex justify-between items-center'>
            <h1>Date requested:</h1>
            <h1>
              {/* {calendar.farmActivity?.starting_date || ''}{' '} */}
              {formatDate(calendar.farmActivity?.starting_date)}
            </h1>
          </div>
          <div className='w-full flex justify-between items-center'>
            <h1>Land size:</h1>
            <h1>{calendar.farmActivity?.land_size || ''}</h1>
          </div>
          <div className='w-full flex justify-between items-center'>
            <h1>Location:</h1>
            <h1>{calendar.farmActivity?.location || ''}</h1>
          </div>
        </div>
        <div className='w-full lg:w-[50%] pb-[5%]'>
          {Array.isArray(calendar.calendarOutput?.event_description) &&
            calendar.calendarOutput.event_description.length > 0 && (
              <DescriptionCalendar
                adminCalendarDescription={
                  calendar.calendarOutput.event_description
                }
              />
          )}
        </div>
      </div>

      {/* bottom section  */}
      <div className='w-full flex lg:flex-row flex-col justify-between items-start gap-3 pt-4'>
        {/* left content */}
        <div className='w-full lg:w-1/2 flex flex-col center'>
          {/* Return investment */}
          <div className='w-full'>
            <h1 className='font-semibold'>Return on Investment</h1>
            <p className='ts pt-3'>
              {calendar.calendarOutput?.return_on_investment || ''}
            </p>
          </div>
          <div className='w-full pt-4'>
            <h1 className='font-semibold'>Business Cases</h1>
            <p className='ts pt-3'>
              {calendar.calendarOutput?.business_cases || ''}
            </p>
          </div>
          <div className='w-full pt-4'>
            <h1 className='font-semibold'>Challenges</h1>
            <p className='ts pt-3'>
              {calendar.calendarOutput?.challenges || ''}
            </p>
          </div>
          <div className='w-full pt-4'>
            <h1 className='font-semibold'>Challenge Mitigators</h1>
            <p className='ts pt-3'>
              {calendar.calendarOutput?.challenge_mitigators || ''}
            </p>
          </div>
          <div className='w-full pt-4'>
            <h1 className='font-semibold'>Value Chain Analysis</h1>
            <p className='ts pt-3'>
              {calendar.calendarOutput?.value_chain || ''}
            </p>
          </div>
          <div className='w-full pt-4'>
            <h1 className='font-semibold'>Preservation Methods</h1>
            <p className='ts pt-3'>
              {calendar.calendarOutput?.preservation_method || ''}
            </p>
          </div>
        </div>
        {/* right content */}
        <div className='w-full lg:w-1/2 flex flex-col justify-center items-start'>
          <div className='w-full bg-darkGreen rounded-md p-4 text-white'>
            <h1 className='font-semibold'>Calendar Information</h1>
            {calendar.calendarOutput?.event_description.map((item, index) => (
              <div className='w-full' key={index}>
                <h1 className='ts font-medium pt-4'>{item.date}</h1>
                <p className='ts pt-2'>{item.event}</p>
              </div>
            ))}
          </div>
          <div className='w-full pt-4'>
            <h1 className='font-semibold'>Planting Methods</h1>
            <p className='ts pt-3'>
              {calendar.calendarOutput?.planting_method || ''}
            </p>
          </div>
          <div className='w-full pt-4'>
            <h1 className='font-semibold'>Harvesting Methods</h1>
            <p className='ts pt-3'>
              {calendar.calendarOutput?.harvesting_method || ''}
            </p>
          </div>
        </div>
      </div>
      {modal.visible && (
        <Modal
          type={modal.type}
          title={modal.title}
          desc={modal.desc}
          link={modal.link}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </div>
  )
}

export default OperationalCalendarDescription
