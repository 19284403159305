import imageCompression from 'browser-image-compression'

export async function resizeAndCompressImage (
  file,
  maxWidth = 800,
  maxHeight = 400,
  maxSizeMB = 1
) {
  const options = {
    maxSizeMB,
    maxWidthOrHeight: Math.max(maxWidth, maxHeight),
    useWebWorker: true
  }

  try {
    const compressedFile = await imageCompression(file, options)
    return compressedFile
  } catch (error) {
    console.error('Error resizing and compressing the image:', error)
    throw error
  }
}
