import { MoreHorizontal } from 'lucide-react'
import { capitalizeWords } from '../../../../../config/services/capitalize'

export const StockMobileColumns = [
  {
    accessorKey: 'farm_name',
    header: 'Farm name',
    cell: context => {
      const value = context.getValue()
      return <div className='w-[120px] ts'>{capitalizeWords(value)}</div>
    }
  },
  {
    accessorKey: 'created_by',
    header: 'Created by',
    cell: context => {
      const value = context.getValue()
      return <div className='w-[100px] ts'>{capitalizeWords(value)}</div>
    }
  },

  {
    accessorKey: 'action',
    header: 'Action',

    enableHiding: false,
    cell: () => {
      return (
        <details className='dropdown'>
          <summary className='btn border-none shadow-none'>
            {' '}
            <MoreHorizontal className='h-4 w-4' />
          </summary>
          <ul className='menu dropdown-content bg-white rounded-md z-[1] right-0 w-40 p-2 shadow'>
            <h1 className='center'>View</h1>
          </ul>
        </details>
      )
    }
  }
]
