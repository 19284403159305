import React from 'react'

const Button = ({ onClick, title, classname }) => {
  return (
    <>
      <button
        type='button'
        className={`${classname} h-[50px] rounded-md ts font-medium`}
        onClick={onClick}
      >
        {title}
      </button>
    </>
  )
}

export default Button
