import React, { useEffect, useState } from 'react'
import DashBoardHeader from './DashBoardHeader/DashBoardHeader'
import WeatherImageIndicator from '../WeatherImageIndicator/WeatherImageIndicator'
import DashBoardChart from './DashBoardChart/DashBoardChart'
import { Plus } from 'lucide-react'
import Modal from '../../SharedComponents/Modal/Modal'
import Hurry from '../../../resources/hurry.png'
import { useAuth } from '../../../../config/contexts/authProvider'

function DashBoard () {
  const { firstTime } = useAuth()
  console.log(firstTime)
  const [isModalOpen, setModalOpen] = useState(false)
  const showModal = () => {
    setModalOpen(true)
  }

  useEffect(() => {
    if (firstTime) {
      showModal()
    }
  }, [firstTime])

  return (
    <div className='w-full h-full  bg-white p-[3%] lg:p-[2%] space-y-5'>
      <div className='w-full flex lg:flex-col flex-col-reverse gap-2 items-center justify-center'>
        <DashBoardHeader
          title='Welcome'
          desc='Manage your organizations activities with ease and take charge'
          btntext='Add new branch'
          link='/farms/new-farm'
          icon={<Plus size={20} />}
        />
        <WeatherImageIndicator />
      </div>
      <DashBoardChart />
      <Modal
        title='Welcome to T-Gaims'
        desc='Create your first calendar now and get personalized guidance tailored to your success in your field.'
        welcomeicon={Hurry}
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
      />
    </div>
  )
}

export default DashBoard
