import { useState } from 'react'

const useSignupState = () => {
  const [signupState, setSignupState] = useState({
    plan: '',
    email: '',
    password: '',
    location: '',
    isAdmin: false,
    cardNumber: ''
  })

  const setSignupField = (field, value) => {
    setSignupState(prevState => ({
      ...prevState,
      [field]: value
    }))
  }

  return { signupState, setSignupField }
}

export default useSignupState
