import { ArrowLeft } from 'lucide-react'
import React, { useRef, useState } from 'react'
import TextInput from '../../../SharedComponents/TextInput/TextInput'
import { useForm } from 'react-hook-form'
import TextArea from '../../../SharedComponents/TextAreaInput/TextAreaInput'
import ImageInput from '../../../SharedComponents/ImageInput/ImageInput'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Modal from '../../../SharedComponents/Modal/Modal'
import useBackendService from '../../../../../config/services/backend-service'
import { resizeAndCompressImage } from '../../../../../config/services/file-resize'

function AddInvestments () {
  const [preview, setPreview] = useState(null)
  const [imageUploaded, setImageUploaded] = useState(false)
  const imageInput = useRef(null)
  const [isModalOpen, setModalOpen] = useState(false)
  const [modal, setModal] = useState({
    type: '',
    title: '',
    desc: '',
    link: '',
    visible: false // Add a visible property for modal
  })

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty, isValid }
  } = useForm({ mode: 'onChange' })

  const { mutate: submitInvestment } = useBackendService(
    '/investments',
    'post',
    {
      onSuccess: () => {
        setModal({
          title: 'Investment added',
          desc: 'Well done , you have successfully created a new investment',
          type: 'success',
          link: '/admin-investments',
          visible: true
        })
        setModalOpen(true)
        reset()
        setPreview(null)
        setImageUploaded(false)
      },
      onError: error => {
        setModal({
          type: 'error',
          title: 'Submission failed',
          desc: 'You are unable to create an investment',
          visible: true
        })
        setModalOpen(true)
      }
    }
  )

  const handleImageChange = async e => {
    const file = e.target.files?.[0]
    if (file) {
      const compressedImage = await resizeAndCompressImage(file)
      const url = URL.createObjectURL(compressedImage)
      setPreview(url)
      setValue('image', compressedImage)
      setImageUploaded(true)
    }
  }

  const handlePencilClick = () => {
    imageInput.current?.click()
  }

  const onSubmit = async data => {
    const { startDate, endDate, payoutDate } = data

    if (new Date(startDate) >= new Date(endDate)) {
      toast.error('Start date must be earlier than end date.')
      return
    }

    const twoWeeksAfterEndDate = new Date(endDate)
    twoWeeksAfterEndDate.setDate(twoWeeksAfterEndDate.getDate() + 14)

    if (
      new Date(payoutDate) <= new Date(endDate) ||
      new Date(payoutDate) > twoWeeksAfterEndDate
    ) {
      toast.error('Payout date must be within two weeks after the end date.')
      return
    }

    const formData = new FormData()
    formData.append('title', data.title)
    formData.append('about', data.about)
    formData.append('price', data.price)
    formData.append('availableSlot', data.slot)
    formData.append('startDate', data.startDate)
    formData.append('endDate', data.endDate)
    formData.append('investmentType', data.investmentType)
    formData.append('payoutDate', data.payoutDate)
    formData.append('repaymentPlan', data.repaymentPlan)
    formData.append('investmentRisk', data.investmentRisk)
    formData.append('status', 'pending')
    formData.append('image', data.image)

    submitInvestment(formData)
  }

  return (
    <div className='flex flex-col lg:gap-10 gap-5 p-5 lg:p-10 bg-white'>
      <div>
        <a href='/admin-investments'>
          <ArrowLeft size={24} />
        </a>
      </div>
      <div className='space-y-0 lg:space-y-2'>
        <h1 className='lg:text-3xl text-xl text-black'>Add new investment</h1>
        <p className='text-[#666666] text-sm lg:text-sm'>
          Enter investment details
        </p>
      </div>
      <form
        action=''
        className='flex flex-col gap-5 lg:gap-10 max-w-fit'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='lg:w-[406px] w-full'>
          <TextInput
            name='title'
            register={register}
            errors={errors}
            text='Title'
          />
        </div>

        <div className='grid w-full gap-1.5'>
          <h1>Upload cover image</h1>
          <ImageInput
            preview={preview}
            imageInput={imageInput}
            handleImageChange={handleImageChange}
            handlePencilClick={handlePencilClick}
          />
          {errors.image && (
            <p className='text-red-500'>{errors.image.message}</p>
          )}
        </div>

        <div className='w-full lg:w-[876px]'>
          <TextArea
            name='about'
            register={register}
            errors={errors}
            text='About'
          />
        </div>

        <div className='flex flex-1 gap-5 lg:gap-[60px] items-center'>
          <div className='flex-[0.5]'>
            <TextInput
              name='price'
              register={register}
              errors={errors}
              text='Price'
            />
          </div>
          <div className='flex-[0.5]'>
            <TextInput
              name='slot'
              register={register}
              errors={errors}
              text='Available slot'
            />
          </div>
        </div>

        <div className='space-y-0 lg:text-start text-center pt-5 lg:pt-0 lg:space-y-2'>
          <h1 className='text-[18px] text-black'>Investment information</h1>
          <p className='text-[#888888] text-sm'>
            Fill out the fields to provide context
          </p>
        </div>

        <div className='flex flex-1 gap-5 lg:gap-[60px] items-center'>
          <div className='flex-[0.5]'>
            <TextInput
              name='startDate'
              type='date'
              register={register}
              errors={errors}
              text='Start Date'
            />
          </div>
          <div className='flex-[0.5]'>
            <TextInput
              name='endDate'
              type='date'
              register={register}
              errors={errors}
              text='End Date'
            />
          </div>
        </div>

        <div className='flex flex-1 gap-5 lg:gap-[60px] items-center'>
          <div className='flex-[0.5]'>
            <TextInput
              name='investmentType'
              register={register}
              errors={errors}
              text='Investment Type'
            />
          </div>
          <div className='flex-[0.5]'>
            <TextInput
              name='payoutDate'
              type='date'
              register={register}
              errors={errors}
              text='Payout Date'
            />
          </div>
        </div>

        <div className='w-full lg:w-[876px]'>
          <TextArea
            name='repaymentPlan'
            register={register}
            errors={errors}
            text='Repayment Plan'
          />
        </div>

        <div className='w-full lg:w-[876px]'>
          <TextArea
            name='investmentRisk'
            register={register}
            errors={errors}
            text='Investment Risk'
          />
        </div>

        <button
          type='submit'
          className={`w-full lg:w-[408px] h-[50px] ${
            isValid && isDirty && imageUploaded
              ? 'proceed-btn bg-darkGreen'
              : 'rounded-[8px] bg-darkash text-black'
          }`}
          disabled={!isValid || !imageUploaded}
        >
          Submit
        </button>
      </form>
      {modal.visible && (
        <Modal
          type={modal.type}
          title={modal.title}
          desc={modal.desc}
          link={modal.link}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </div>
  )
}

export default AddInvestments
