import { Plus } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import DashBoardHeader from '../DashBoard/DashBoardHeader/DashBoardHeader'
import StockDataTable from '../../StockPage/StockDataTable/StockDataTable'
import useBackendService from '../../../../config/services/backend-service'
import { useAuth } from '../../../../config/contexts/authProvider'
import Modal from '../../SharedComponents/Modal/Modal'
import Hurry from '../../../resources/hurry.png'

const StockPage = () => {
  const { firstTime, user } = useAuth()
  const [isModalOpen, setModalOpen] = useState(false)
  const showModal = () => {
    setModalOpen(true)
  }

  useEffect(() => {
    if (firstTime) {
      showModal()
    }
  }, [firstTime])

  const [data, setData] = useState([])

  const { mutate } = useBackendService(
    `/stock_records/parent/${user.farmId}`,
    'get',
    {
      onSuccess: data => {
        setData(data.data)
      },
      onError: error => {
        console.log(error)
      }
    }
  )
  useEffect(() => {
    mutate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='farmwrapper'>
      <DashBoardHeader
        title='Stock'
        btntext='Add new stock report'
        link='/stocks/new-stock'
        icon={<Plus size={20} />}
      />
      <StockDataTable data={data} />

      <Modal
        title='Welcome to T-Gaims'
        desc='Create your first calendar now and get personalized guidance tailored to your success in your field.'
        welcomeicon={Hurry}
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
      />
    </div>
  )
}

export default StockPage
