import React, { useEffect, useState } from 'react'
import TrendingNews from './TrendingNews/TrendingNews'
import ForYouSection from './ForYouSection/ForYouSection'
import useBackendService from '../../../../config/services/backend-service'
const RegularAgriNews = ({ unlock = false }) => {
  const [data, setData] = useState([])

  const { mutate: fetchNews } = useBackendService(
    '/articles?status=approved',
    'get',
    {
      onSuccess: response => {
        setData(response.data)
      },
      onError: error => {
        console.error('Failed to fetch products:', error)
      }
    }
  )

  useEffect(() => {
    fetchNews()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className='w-full h-full bg-white p-[2%]'>
      <TrendingNews data={data} unlock={unlock} />
      <ForYouSection data={data} unlock={unlock} />
    </div>
  )
}

export default RegularAgriNews
