import React from 'react'
import DataTable from '../../../SharedComponents/DataTable/DataTable'
import Pagination from '../../../SharedComponents/Pagination/Pagination'
import EmptyDataContent from '../../../SharedComponents/EmptyDataContent/EmptyDataContent'
import { columns } from '../OperationsTableColumn/TableColumn'
import { OperationMobileColumns } from '../OperationsTableColumn/OperationMobileColumn/OperationMobileColumn'
import useWindowSize from '../../UseWindowSize/UseWindowSize'

function OperationsDataTable ({ data, title, nav }) {
  const { width } = useWindowSize()
  const columnsforoperation = width > 1024 ? columns : OperationMobileColumns
  const onClick = data => {
    console.log(data)
  }

  return (
    <div className='w-full'>
      <DataTable
        data={data}
        columns={columnsforoperation}
        filter={false}
        pagination={data.length !== 0 && <Pagination />}
        emptydatacontent={
          <div className='flex flex-col items-center justify-center w-full '>
            <EmptyDataContent
              image={require('../../../../resources/farmemptyimage.png')}
              text='No operations yet'
              desc='Once an operation has been added, you will see an overview here.'
            />
          </div>
        }
        onClick={onClick}
        title={title}
        nav={nav}
      />
    </div>
  )
}

export default OperationsDataTable
