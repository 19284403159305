import React, { useState } from 'react'
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
  getSortedRowModel
} from '@tanstack/react-table'
import Pagination from '../../../SharedComponents/Pagination/Pagination'
import ProductFilter from '../ProductFilter/ProductFilter'

const DataTable = ({
  data,
  columns,
  productfilter,
  pagination,
  emptydatacontent
}) => {
  const [sorting, setSorting] = useState([])
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting
    }
  })

  return (
    <>
      {productfilter && <ProductFilter data={data} table={table} />}
      <div className='w-full text-[10px] lg:text-sm overflow-x-auto mt-[2%] '>
        <table className='w-full  bg-[#F9F9F9] text-sm'>
          {/* head */}
          {data.length === 0
            ? (
              <div className='w-full h-[400px] '>{emptydatacontent}</div>
              )
            : (
              <>
                <thead className='text-left'>
                  {table.getHeaderGroups().map(headerGroup => (
                    <tr
                      key={headerGroup.id}
                      className=' h-[60px] ts'
                    >
                      {headerGroup.headers.map(header => (
                        <th
                          key={header.id}
                          onClick={header.column.getToggleSortingHandler()}
                          className='text-[#444444] text-sm lg:text-base whitespace-nowrap px-4 py-2 font-medium text-left'
                        >
                          {header.isPlaceholder
                            ? null
                            : (
                              <div>
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                              </div>
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody>
                  {table.getRowModel().rows.map(row => (
                    <tr
                      key={row.id}
                      className='border-b border-[#DDDDDD] h-[60px]'
                    >
                      {row.getVisibleCells().map(cell => (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </>
              )}
        </table>

        {pagination && (
          <Pagination
            next={() => {
              table.nextPage()
            }}
            previous={() => {
              table.previousPage()
            }}
            nextpagedisabled={!table.getCanNextPage()}
            previouspagedisabled={!table.getCanPreviousPage()}
            totalPages={table.getPageCount()}
            current={table.getState().pagination.pageIndex + 1}
          />
        )}
      </div>
    </>
  )
}

export default DataTable
