import React, { useState } from 'react'
import BackButton from '../../../SharedComponents/BackButton/BackButton'
import { Controller, useForm } from 'react-hook-form'
import SelectInput from '../../../SharedComponents/SelectInput/SelectInput'
import TextInput from '../../../SharedComponents/TextInput/TextInput'
import { CalendarDays } from 'lucide-react'
import CalendarSelector from '../OperationalCalendarSelector/OperationalCalendarSelector'
import Modal from '../../../SharedComponents/Modal/Modal'
import useBackendService from '../../../../../config/services/backend-service'
import { format, startOfToday } from 'date-fns'
import TextArea from '../../../SharedComponents/TextAreaInput/TextAreaInput'
import { useAuth } from '../../../../../config/contexts/authProvider'

const OperationalCalendarCreate = () => {
  const { user } = useAuth()
  const [isModalOpen, setModalOpen] = useState(false)
  const [modal, setModal] = useState({
    type: '',
    title: '',
    desc: '',
    link: '',
    visible: false // Add a visible property for modal
  })

  const today = startOfToday()
  const [selectedDay, setSelectedDay] = useState('')
  const [valueChainChoice, setValueChainChoice] = useState('')
  const formattedSelectedDay = selectedDay
    ? format(new Date(selectedDay), 'dd-MM-yyyy')
    : ''

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch // To track form changes dynamically
  } = useForm({ mode: 'onChange' })

  const { mutate: createCalendar } = useBackendService('/calendar', 'post', {
    onSuccess: () => {
      setModal({
        type: 'success',
        title: 'Calendar submitted',
        desc: `Well done. You have successfully submitted a calendar. Please look out for your response on ${formattedSelectedDay}`,
        link: '/operational-calendar',
        visible: true
      })
      setModalOpen(true) // Open modal on success
    },
    onError: () => {
      setModal({
        type: 'error',
        title: 'Submission Failed',
        desc: 'There was an error submitting your calendar. Please try again.',
        visible: true
      })
      setModalOpen(true) // Open modal on error
    }
  })

  const onSubmit = async data => {
    const final = { ...data, farmId: user.farmId }
    createCalendar(final)
  }

  const questionOptions = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' }
  ]

  // const products = [
  //   { label: 'Yes', value: 'Yes' },
  //   { label: 'No', value: 'No' }
  // ]
  const status = [
    { label: 'Pending', value: 'Pending' },
    { label: 'Completed', value: 'Completed' }
  ]

  // Watch form fields to enable button based on form state
  const watchedFields = watch()
  const isFormComplete =
    valueChainChoice === 'Yes'
      ? watchedFields.projectstatus &&
        watchedFields.challenges &&
        watchedFields.helpareas &&
        watchedFields.details
      : watchedFields.location &&
        watchedFields.capital &&
        watchedFields.landsize &&
        watchedFields.date

  const handleSave = () => {
    if (selectedDay) {
      setValue('date', formattedSelectedDay)
      console.log('Selected Date:', selectedDay)
    }
  }

  return (
    <div className='farmwrapper'>
      <BackButton />
      <div className='space-y-0 lg:space-y-1'>
        <h1 className='lg:text-3xl text-xl text-black'>Create calendar</h1>
        <p className='text-[#666666] text-sm lg:text-sm'>
          Fill in the details of your calendar
        </p>
      </div>

      <form className='form-wrapper' onSubmit={handleSubmit(onSubmit)}>
        <div className='flex flex-col lg:flex-row gap-5 lg:gap-10 items-center'>
          <div className='w-full lg:w-[418px]'>
            <TextInput
              name='product'
              register={register}
              errors={errors}
              text='Product'
              placeholder='Input product'
            />
          </div>

          <div className='w-full lg:w-[418px]'>
            <SelectInput
              control={control}
              Controller={Controller}
              data={questionOptions}
              title='Have you gone into this value chain before?'
              name='valuechain'
              placeholder='Select answer'
              onChange={setValueChainChoice}
            />
          </div>
        </div>

        {/* Conditionally render input based on Yes/No selection */}
        {valueChainChoice === 'Yes' && (
          <>
            <div className='w-full lg:w-[418px]'>
              <SelectInput
                control={control}
                Controller={Controller}
                data={status}
                title='Project status'
                name='projectstatus'
                placeholder='Select status'
              />
            </div>
            <div className='w-full'>
              <TextArea
                name='challenges'
                register={register}
                errors={errors}
                text='Challenges encountered?'
                placeholder='Input here'
              />
            </div>
            <div className='w-full'>
              <TextArea
                name='helpareas'
                register={register}
                errors={errors}
                text='Areas you need help?'
                placeholder='Input here'
              />
            </div>
            <div className='w-full'>
              <TextArea
                name='details'
                register={register}
                errors={errors}
                text='Any other details?'
                placeholder='Input here'
              />
            </div>
          </>
        )}

        {valueChainChoice === 'No' && (
          <>
            <div className='flex flex-col lg:flex-row gap-5 lg:gap-10 items-center'>
              <div className='w-full lg:w-[418px]'>
                <TextInput
                  name='location'
                  register={register}
                  errors={errors}
                  text='Location'
                  placeholder='Input here'
                />
              </div>

              <div className='w-full lg:w-[418px]'>
                <TextInput
                  name='capital'
                  register={register}
                  errors={errors}
                  text='Capital'
                  placeholder='Input here'
                />
              </div>
            </div>
            <div className='flex flex-col lg:flex-row gap-5 lg:gap-10 items-center'>
              <div className='w-full lg:w-[418px]'>
                <TextInput
                  name='landsize'
                  register={register}
                  errors={errors}
                  text='Land Size'
                  placeholder='Input here'
                />
              </div>

              <div className='w-full lg:w-[418px]'>
                <TextInput
                  name='date'
                  register={register}
                  errors={errors}
                  text='Starting Date'
                  dvalue={formattedSelectedDay}
                  placeholder='Input here'
                  calendaricon={
                    <CalendarDays
                      size={20}
                      onClick={() =>
                        document.getElementById('calendar').showModal()}
                    />
                  }
                />
              </div>
            </div>
          </>
        )}

        <div className='w-full lg:w-[418px]'>
          <button
            type='submit'
            className={`w-full h-[50px] ts ${
              isFormComplete
                ? 'proceed-btn bg-darkGreen'
                : 'rounded-[8px] bg-darkash text-black'
            }`}
            disabled={!isFormComplete}
          >
            Create Calendar
          </button>
        </div>
      </form>

      <CalendarSelector
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
        today={today}
        onSave={handleSave}
      />

      {/* Conditionally render the modal */}
      {modal.visible && (
        <Modal
          type={modal.type}
          title={modal.title}
          desc={modal.desc}
          link={modal.link}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </div>
  )
}

export default OperationalCalendarCreate
