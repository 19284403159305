import React from 'react'
import Image from '../../../resources/wateryplant.png'
import Logo from '../../../resources/logo-white.png'
import ProPlanAccountSignUp from './ProPlanAccountSignUp'
import ProPlanAppPurposeRegistration from './ProPlanAppPurposeRegistration'
import ProPlanEmailVerification from './ProPlanEmailVerification'
import NavigationSteps from '../../NavigationSteps/NavigationSteps'
import useWindowSize from '../../EnterpriseApp/UseWindowSize/UseWindowSize'

const ProPlan = () => {
  const { width } = useWindowSize()
  const isTabletView = width >= 768 && width <= 1024
  return (
    <>
      {isTabletView ? (
        <div className='w-full min-h-screen  bg-waterplant bg-cover p-[5%]'>
          <div className='center w-full'>
            <img alt='' src={Logo} className='w-[100px]' />
          </div>
          <div className='w-full center flex-col text-white text-center space-y-2 mt-[2%]'>
            <h1 className='text-2xl  w-full'>
              Manage your agribusiness efficiently
            </h1>
            <p className='w-[80%] leading-[16px] font-[400] text-[16px]'>
              Building the best and more efficient platform for agribusiness
              management.
            </p>
          </div>
          <div className='w-[90%] p-5 bg-white mt-[4%] mx-auto rounded-md'>
            <div className='w-full'>
              <NavigationSteps
                First={ProPlanAccountSignUp}
                Second={ProPlanAppPurposeRegistration}
                Third={ProPlanEmailVerification}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className='wrapper'>
          {/* left content */}
          <div className='left-content '>
            <div className='absolute top-[5%] left-1/2 lg:left-[10%] transform -translate-x-1/2 -translate-y-1/2 center mx-auto'>
              <img alt='' src={Logo} className='w-[80px] lg:w-[100px]' />
            </div>
            <img alt='' src={Image} className='object-contain' />
            <div className='image-text-wrapper'>
              <h1 className='text-2xl lg:text-5xl w-full'>
                Manage your <br /> agribusiness efficiently
              </h1>
              <p className='w-[100%] lg:w-[70%] lg:text-lg leading-5 text-sm'>
                Building the best and more efficient platform for agribusiness
                management.
              </p>
            </div>
          </div>
          {/* right content */}
          <div className='right-content'>
            <NavigationSteps
              First={ProPlanAccountSignUp}
              Second={ProPlanAppPurposeRegistration}
              Third={ProPlanEmailVerification}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default ProPlan
