import React from 'react'
import DataTable from '../../../SharedComponents/DataTable/DataTable'
import { columns } from '../AdminRequestColumn/AdminRequestColumn'
import Pagination from '../../../SharedComponents/Pagination/Pagination'
import { useNavigate } from 'react-router-dom'

const AdminRequestDataTable = ({ data }) => {
  const navigate = useNavigate()

  const handleRowClick = data => {
    navigate(`/admin-requests/${data.id}`)
  }
  return (
    <div>
      <DataTable
        data={data}
        columns={columns}
        filter={false}
        pagination={data.length !== 0 && <Pagination />}
        rowClick={handleRowClick}
      />
    </div>
  )
}

export default AdminRequestDataTable
