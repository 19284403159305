import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../../../components/Header/Header'

const HeroSection = ({ bgImg }) => {
  return (
    <div
      className=' lg:h-[900px] h-[400px] bg-cover bg-center relative'
      style={{
        backgroundImage: `url(${bgImg})`
      }}
    >
      <div className='bg-black z-50 absolute top-0 right-0 left-0 w-full h-full opacity-40' />
      <Header />
      <section className='relative  lg:py-[64px] px-[5%] py-5 flex flex-col lg:gap-4 gap-5 '>
        <div className='flex justify-start gap-10 lg:mt-[5%]'>
          <div className='flex flex-col gap-[24px] lg:gap-[60px] lg:items-start items-start w-full lg:w-[60%] z-[50]  '>
            <div className='flex flex-col gap-5 lg:gap-5 bg-black bg-opacity-5 backdrop-blur-sm w-[100%] h-[300px] lg:h-full   p-[25px] rounded-[8px] '>
              <p className='font-[600] text-white text-[24px] lg:text-[48px] text-left lg:leading-[61px]'>
                Your One Stop Shop For All <br className='hidden lg:flex' /> Agricultural Matters
              </p>
              <p className='lg:font-[500] text-white text-left text-[14px] lg:text-[18px] leading-[21px] lg:leading-[24px]'>
                Optimize Your Yields, Reduce Costs, and Ensure Sustainability
                <br className='hidden lg:flex' /> with Our Cutting-Edge Solution for agri business.
              </p>
              <div className='flex w-full justify-start items-center mt-5'>
                <Link to='about' className='w-full lg:w-[60%]'>
                  <div className='lg:py-[10px]  center  h-[53px] text-center lg:mb-0 mb-20 p-2 lg:px-6 bg-primary rounded-lg cursor-pointer'>
                    <p className='font-[500] text-[#232221] text-[14px] lg:text-[16px]'>
                      Get started with T-GAIMS
                    </p>
                  </div>
                </Link>
              </div>
            </div>

          </div>
        </div>
      </section>
    </div>
  )
}

export default HeroSection
