import { capitalizeWords } from '../../../../../config/services/capitalize'

export const columns = [
  {
    accessorKey: 'title',
    header: 'Title',
    cell: context => (
      <span className='ts'>{capitalizeWords(context.getValue())}</span>
    )
  },
  {
    accessorKey: 'created_by_name',
    header: 'Created By',
    cell: context => (
      <span className='ts'>{capitalizeWords(context.getValue())}</span>
    )
  },

  {
    accessorKey: 'created_at',
    header: 'Created At',
    cell: context => {
      const value = context.getValue()
      const formattedDate = new Date(value).toLocaleDateString()
      const formattedTime = new Date(value).toLocaleTimeString()
      return (
        <span>
          {formattedDate}-{formattedTime}
        </span>
      )
    }
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: context => {
      const value = context.getValue()
      return (
        <span
          style={{
            color:
              value === 'rejected'
                ? 'red'
                : value === 'approved'
                  ? 'green'
                  : '#F29339'
          }}
        >
          {capitalizeWords(value)}
        </span>
      )
    }
  },
  {
    accessorKey: 'action',
    header: 'Action',
    enableHiding: false,
    cell: () => {
      return <p className=' font-[500] text-[16px] cursor-pointer '>View</p>
    }
  }
]
