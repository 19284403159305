import React from 'react'
// import { FaPlus } from 'react-icons/fa'
import Footer from '../../components/Footer/Footer'
import { Link } from 'react-router-dom'
import './About.css'
import Header from '../../components/Header/Header'

function About () {
  const bgImg = require('../../assets/about-hero.png')
  return (
    <main className='about'>
      <div
        className=' lg:h-[900px] h-[400px] bg-cover bg-center relative'
        style={{
          backgroundImage: `url(${bgImg})`
        }}
      >
        <div className='bg-black z-30 absolute top-0 right-0 left-0 w-full h-full opacity-40' />
        <Header />
        <section className='relative  lg:py-[64px] px-[5%] py-5 flex flex-col lg:gap-4 gap-5 '>
          <div className='lg:px-[100px] ' />
          <div className='hero_content z-[48] w-full lg:w-[75%] mx-auto'>
            <p className='text-[#ADEE68] text-center text-[24px] lg:text-[48px] font-[800] w-full leading-[29px] lg:leading-[73px]'>
              Discover Your Ultimate Farming Partner for Success
            </p>
            <p className='font-[400] text-white lg:text-[#dddddd] text-[14px] lg:text-[20px] text-center leading-[21px] lg:leading-[30px]'>
              Join us in revolutionizing the way you manage your farm and
              livestock, ensuring <br /> a prosperous future for your agricultural
              endeavors
            </p>
          </div>
          <div className=' flex flex-row justify-center items-center z-[50] w-full '>
            <Link to='/products' className='w-full mx-auto center'>
              <div className='find_button h-[46px] lg:h-[53px]'>
                <p className=' text-[14px] lg:text-[16px] font-[500] text-primary '>Learn More</p>
              </div>
            </Link>
          </div>
        </section>
      </div>
      <section className='goals w-full lg:w-[75%]'>
        <div className='goals_content'>
          <p>We are redifining the process of managing agricultural ventures and businesses</p>
        </div>
        <div className='goals_content'>
          <p>
            We want to build the best tools to help both ambitious and
            established farmers, food scientists and crop managers manage their
            agri business better and more efficiently.
          </p>
        </div>
        <Link to='/products' className='w-full'>
          <div className='goals_button h-[46px] lg:h-[53px] center w-full lg:w-[40%]'>
            <p className=' text-[12px] lg:text-[20px] '>
              Become part of our journey
            </p>
          </div>
        </Link>
      </section>

      <section className='tgaims-customers'>
        <div>
          <p className=' text-primary text-[22px] lg:text-[38px] font-[600] text-start '>
            Who are T-Gaims customers
          </p>
          <p className=' text-[14px] lg:text-[18px] lg:w-[75%] text-grey'>
            Whether you're overseeing agriculture investment portfolios, farms
            (large, medium or small scale entreprise) our features ensure you
            have everything you need to succeed.
          </p>
        </div>

        <div className=' flex flex-col lg:flex-row gap-[28px] lg:gap-[100px] pt-[20px] lg:p-[30px] lg:justify-center  '>
          <div className=' flex flex-col lg:w-[406px] gap-[30px] bg-[#F7F7F7] p-[15px] rounded-lg  '>
            <img
              src={require('../../assets/Rectangle 218.png')}
              alt=''
              className=' w-full lg:w-[346px] h-[200px] object-cover lg:h-[280px] '
            />
            <div className=' flex flex-col gap-[10px] lg:gap-[13px]  '>
              <p className='  text-black font-[700] text-[14px] lg:text-[24px] '>
                Small scale farmers
              </p>
              <p className=' text-grey text-[13px] lg:text-[18px]  '>
                Use our advanced tools to optimize resource usage. Implement
                sustainable practice and boost yields. Whether you're overseeing
                a large-scale enterprise or a small farm.
              </p>
            </div>
          </div>
          <div className=' flex flex-col lg:w-[406px]  gap-[30px] bg-[#F7F7F7] p-[15px] rounded-lg  '>
            <img
              src={require('../../assets/Rectangle 219.png')}
              alt=''
              className=' w-full lg:w-[346px] h-[200px] object-cover lg:h-[280px] '
            />
            <div className=' flex flex-col gap-[13px]  '>
              <p className='  text-black font-[700] text-[14px] lg:text-[24px]'>
                Large scale farmers
              </p>
              <p className=' text-grey text-[13px] lg:text-[18px]  '>
                Use our advanced tools to optimize resource usage. Implement
                sustainable practice and boost yields. Whether you're overseeing
                a large-scale enterprise or a small farm.
              </p>
            </div>
          </div>
          <div className=' flex flex-col lg:w-[406px]  gap-[30px] bg-[#F7F7F7] p-[15px] rounded-lg  '>
            <img
              src={require('../../assets/Rectangle 219-1.png')}
              alt=''
              className=' w-full lg:w-[346px] h-[200px] object-cover lg:h-[280px] '
            />
            <div className=' flex flex-col gap-[13px]  '>
              <p className='  text-black font-[700] text-[14px] lg:text-[24px] '>
                Agricultural Experts
              </p>
              <p className=' text-grey text-[13px] lg:text-[18px]  '>
                Use our advanced tools to optimize resource usage. Implement
                sustainable practice and boost yields. Whether you're overseeing
                a large-scale enterprise or a small farm.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className='flex flex-col gap-10 items-center lg:px-[100px] px-5 py-10 lg:py-[40px] '>
        <div className=' flex flex-col gap-3 text-start lg:text-center  w-full'>
          <p className=' font-[600] text-[18px] lg:text-[40px]  text-primary '>
            Why choose T-Gaims
          </p>
          <p className=' text-grey lg:text-[18px] text-[13px] text-start lg:text-center '>
            Whether you're overseeing agriculture investment portfolios, farms
            (large, medium or small <br /> scale entreprise) our features ensure
            you have everything you need to succeed.
          </p>
        </div>
        <div className=' flex flex-col lg:flex-row  gap-10 '>
          <div className=' flex flex-[0.5] flex-col  gap-4 justify-between'>
            <div className=' flex flex-col  bg-[#F7F7F7]  gap-4 rounded-lg p-[15px] lg:p-[30px]  '>
              <img
                src={require('../../assets/1.png')}
                className=' w-6 lg:w-9 h-6 lg:h-9 '
                alt=''
              />
              <div className=' flex flex-col gap-3 '>
                <p className=' text-[13px] leading-[19.5px] lg:leading-[30px] lg:text-[18px] text-grey font-[400] '>
                  T-GAIMS is dedicated to advancing sustainable and efficient agribusiness
                  practices across all agricultural spectra. Our comprehensive platform called T-
                  GAIMS reflects our unwavering commitment to furnishing users with
                  actionable insights and valuable information on everything Agriculture.
                </p>
              </div>
            </div>
          </div>
          <div className=' flex flex-[0.5] flex-col gap-4 justify-between'>
            <div className=' flex flex-col bg-[#F7F7F7]  gap-4 rounded-lg p-[15px] lg:p-[30px]  '>
              <img
                src={require('../../assets/2_1.png')}
                className=' w-6 lg:w-9 h-6 lg:h-9 '
                alt=''
              />
              <div className=' flex flex-col gap-3 '>
                <p className=' text-[13px] leading-[19.5px] lg:leading-[30px] lg:text-[18px] text-grey font-[400] '>
                  Expertise, Experience, and Enterprise are our core values!. These values
                  compel us to serve the whole agricultural community, hence, our platform is
                  designed to enhance all facets of agribusiness and support these journeys to
                  success.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className=' flex flex-col lg:flex-row  gap-10 '>
          <div className=' flex flex-[0.5] flex-col gap-4 justify-between'>
            <div className=' flex flex-col  bg-[#F7F7F7]  gap-4 rounded-lg p-[15px] lg:p-[30px]  '>
              <img
                src={require('../../assets/3.png')}
                className=' w-6 lg:w-9 h-6 lg:h-9 '
                alt=''
              />
              <div className=' flex flex-col gap-3 '>
                <p className=' text-[13px] leading-[19.5px] lg:leading-[30px] lg:text-[18px] text-grey font-[400] '>
                  ⁠Your voice is the seed that grows our success! We regularly seek your
                  feedbacks and insights, using them to improve our products and fostering a
                  strong, collaborative relationship built on trust and mutual growth.
                </p>
              </div>
            </div>
          </div>
          <div className=' flex flex-[0.5] flex-col gap-4 justify-between'>
            <div className=' flex flex-col bg-[#F7F7F7]  gap-4 rounded-lg p-[15px] lg:p-[30px]  '>
              <img
                src={require('../../assets/4.png')}
                className=' w-6 lg:w-9 h-6 lg:h-9 '
                alt=''
              />
              <div className=' flex flex-col gap-3 '>
                <p className=' text-[13px] leading-[19.5px] lg:leading-[30px] lg:text-[18px] text-grey font-[400] '>
                  Join T-GAIMS - Join the largest and most influential community for everything
                  Agriculture and Agribusiness, where collaboration, innovation, and growth
                  thrive. Connect, learn, and flourish with T-GAIMS!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className='w-full relative flex items-center justify-center'>
        <h1 className='absolute font-[600] text-base text-[18px] leading-[30px] lg:text-[36px] lg:leading-[63px] text-center px-[5%] text-white'>
          We help you take charge and run your Agricultural processes more efficiently all from one platform
        </h1>
        <img
          src={require('../../assets/plant.png')}
          className='w-full h-[200px] lg:h-[400px] object-cover'
          alt=''
        />
      </div>
      <Footer />
    </main>
  )
}

export default About

/* export const Faqs = () => (
  <div className='faqs'>
    <p className=' text-[48px] lg:text-[64px] text-center lg:text-start '>
      Faqs
    </p>
    <div className=' flex flex-col gap-3 lg:gap-10 '>
      <Accordion text='How does TGAIMS help increase farm productivity?' />
      <Accordion text='What types of farms can benefit from TGAIMS?' />
      <Accordion text='How does FarmMaster assist with market insights?' />
      <Accordion text='Can multiple users access the system simultaneously' />
      <Accordion text='How can TGAIMS help with sustainability' />
    </div>
  </div>
) */

/* const Accordion = ({ text }) => {
  const [visible, setVisible] = useState(false)

  const handleClick = () => {
    setVisible(!visible)
  }
  return (
    <div
      className='border-b w-full border-[#000] py-5 font-[700] text-[32px] '
      onClick={handleClick}
    >
      <div className='accordion'>
        <p className=' text-[18px] lg:text-[24px] '>{text}</p>

        <div className='cursor-pointer'>
          <FaPlus
            color='#144618'
            size={18}
            className={`transform transition-transform duration-300 ${
              visible ? 'rotate-180' : ''
            }`}
          />
        </div>
      </div>
      {visible && (
        <div className=' p-4 border-l my-3  border-r border-secondary'>
          <p className=' text-[16px] text-primary '>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus
            velit veritatis tempora? Nemo quas quos ad mollitia harum, earum
            ducimus ea placeat neque dolorem accusamus voluptate reprehenderit
            voluptatem consectetur dicta.
          </p>
        </div>
      )}
    </div>
  )
} */
