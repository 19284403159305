import React, { useEffect, useState } from 'react'
import './index.css'
import DashBoardHeader from '../DashBoard/DashBoardHeader/DashBoardHeader'
import { Plus } from 'lucide-react'
import InventoryCount from './InventoryCount/InventoryCount'
import ProductDataTable from './ProductDataTable/ProductDataTable'
import useBackendService from '../../../../config/services/backend-service'
import { useAuth } from '../.././../../config/contexts/authProvider'

const ProductsPage = () => {
  const [data, setData] = useState([])
  const { user } = useAuth()

  const { mutate: fetchProducts } = useBackendService(`/products/list_for_farm/${user.farmId}`, 'get', {
    onSuccess: response => {
      setData(response.data)
    },
    onError: error => {
      console.error('Failed to fetch products:', error)
    }
  })

  useEffect(() => {
    fetchProducts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section className='w-full p-[3%] bg-white space-y-5'>
      <div className='w-full flex flex-col-reverse lg:flex-col justify-center items-center gap-2'>
        <DashBoardHeader
          btntext='Add new product'
          link='/farmproducts/new-Product'
          icon={<Plus size={20} />}
        />
        <InventoryCount data={data} />
      </div>
      <ProductDataTable data={data} />
    </section>
  )
}

export default ProductsPage
